import { useContext, useEffect, useState } from "react";
import _config from "../../../config";
import { TBIG_GALLERY } from "../../../@types/big_gallery";
import { Auth } from "../../../contexts/auth";
import { toast } from "sonner";
import { Errors } from "../../../utils/error";
import axios from "axios";
import { Path } from "../../../constants";
import GalleryCard from "../gallery-card";
import Loader from "../../../components/loader";

interface TProps {}
export const Gallery = (props: TProps) => {
    const [gallerys, setGallerys] = useState<TBIG_GALLERY[]>([]);
    const [isLoading, setIsloading] = useState(true);
    const { token } = useContext(Auth);

    useEffect(() => {
        const load = async () => {
            setIsloading(true);
            try {
                const rs = await axios.get(`${_config.api_server_uri}${Path.Admin.Big_gallery.index}`, { headers: { Authorization: token } });
                if (rs.status !== 200) toast.error(rs.data.message);
                setGallerys(rs.data.gallerys);
            } catch (error) {
                Errors.handler(error);
            } finally {
                setIsloading(false);
            }
        };
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isLoading)
        return (
            <div className="w-full h-[80vh] flex justify-center items-center">
                <Loader />;
            </div>
        );
    return (
        <div>
            {gallerys.length === 0 ? (
                <div className="w-full h-auto dark:text-white text-black flex justify-center items-center p-16 ">no application</div>
            ) : (
                <div className="mt-5">
                    <GalleryCard big_gallerys={gallerys} />
                </div>
            )}
        </div>
    );
};
