import { useState, useEffect } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import config from "../../../../config";
import ChekoutFrom from "./chekoutFrom";
import axios from "axios";
import stripePk from "../../../../utils/stripe-pk";
import NotFound from "../../../NotFound";
import { Path } from "../../../../constants";
import Loader from "../../../../components/loader";
import { useAuth } from "../../../../hook/auth";

const Pay = () => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const params = useParams();
    const [clientScret, setClientScret] = useState<string | null>(null);
    const [price, setPrice] = useState<number | null>(null);
    const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
    const [message, setMessage] = useState<string | null>(null);
    const [isloading, setIsloading] = useState<boolean>(true);
    const auth = useAuth();

    useEffect(() => {
        const loadClientScret = async () => {
            try {
                setIsloading(true);
                const paintId = params.id;
                if (!paintId) return;
                const responce = await axios.post(`${config.api_server_uri}${Path.Dashboard.paint.client_secret.replace(":paintid", paintId)}`);
                if (responce.status === 200) {
                    const body = responce.data;
                    setClientScret(body.client_secret);
                    setPrice(body.prise);
                } else {
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsloading(false);
            }
        };
        const loadKeyStripe = async () => {
            if (!auth.token) return;
            const pk = await stripePk(auth.token);

            if (pk) {
                setStripePromise(loadStripe(pk, { apiVersion: "2020-03-02" }));
            } else {
                setMessage("error to get public stripe key");
            }
        };
        loadClientScret();

        loadKeyStripe();
    }, [auth.isloading, auth.token, auth.user, params.id]);
    if (isloading) return <Loader />;
    if (!clientScret || !price) return <NotFound />;

    return (
        <div className="w-screen h-screen flex justify-start items-center flex-col dark:bg-gray-800 dark:text-white text-black">
            <div className="w-full h-20 flex justify-center items-center">
                <h1>Pay paint</h1>
            </div>
            {message ? (
                <div className="w-full flex justify-center items-center">
                    <div className="flex p-4 mb-4 bg-red-100 rounded-lg dark:bg-red-200" role="alert">
                        <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-red-700 dark:text-red-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="ml-3 text-sm font-medium text-red-700 dark:text-red-800">{message}</div>
                        <button
                            type="button"
                            className="ml-auto -mx-1.5 -my-1.5 bg-red-100 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300"
                            aria-label="Close"
                            onClick={() => setMessage(null)}
                        >
                            <span className="sr-only">Close</span>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            ) : null}
            {stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret: clientScret, appearance: { theme: "night" } }}>
                    <ChekoutFrom prise={formatter.format(price)} />
                </Elements>
            )}
        </div>
    );
};

export default Pay;
// export const loader: LoaderFunction = async ({ params }) => {
//     const isLog = await isLogin();
//     if (!isLog) return redirect(`/login?paintId=${params.id}`);
//     if (isLog.user) {
//         const { user } = isLog;
//         const paint: TPaint | undefined = user.paints.find((paint) => paint.id === params.id);
//         if (paint) {
//             try {
//                 const result = await axios.post(`${config.api_server_uri}/dashboard/paint/clientSecret`, { paintId: paint.id }, { headers: { authorization: user.token } });
//                 console.log(result);
//                 if (result.status === 200) {
//                     return {
//                         paint,
//                         user: user,
//                         clientSecret: result.data.clientSecret,
//                         price: result.data.price,
//                     };
//                 } else return redirect("/dashboard");
//             } catch (err) {
//                 return redirect("/dashboard");
//             }
//         } else return redirect("/dashboard");
//     } else {
//         return redirect("/dashboard");
//     }
// };
