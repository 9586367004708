import { useEffect, useState } from "react";
import _config from "../../config";
import { createPortal } from "react-dom";
import { Path } from "../../constants";
import { TSVG } from "../../@types/svg";
import axios from "axios";
import { toast } from "sonner";
const ImageList = (props: { config: Config; onChange: (config: Config) => void }) => {
    const { config, onChange } = props;
    const [showList, setShowList] = useState<boolean>(false);
    const [currentImageSelect, setCurrentImageSelect] = useState(0);
    const [currentSvgSelect, setcurrentSvgSelect] = useState(0);
    const [currentSection, setCurrentSection] = useState<"svg" | "image">("image");
    const [svgs, setSvg] = useState<TSVG[]>([]);
    useEffect(() => {
        const loadSvg = async () => {
            try {
                const responce = await axios.get(`${_config.api_server_uri}${Path.Svg.index}`);
                if (responce.status === 200) {
                    setSvg(responce.data.svgs);
                } else {
                    toast.error(responce.data.message, { duration: 3000 });
                }
            } catch (error) {
                toast.error("error to load svg", { duration: 3000 });
            }
        };
        loadSvg();
    }, []);

    if (!showList)
        return (
            <div className="w-full">
                <button
                    onClick={() => {
                        setShowList((showList) => !showList);
                        window.scrollTo({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                        });
                    }}
                    type="button"
                    className="flex text-white  focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                >
                    config images
                </button>
            </div>
        );

    return createPortal(
        <div className={`w-full h-auto absolute top-0 left-0 bg-gray-800 flex justify-center items-center pt-20 z-40`}>
            <div className="absolute top-[50px] right-[50px] w-[100px] h-[100px] ">
                <div
                    className="flex justify-center items-center h-[80px] w-[80px]  p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer"
                    onClick={() => setShowList(false)}
                >
                    <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>
            <div className="flex w-full px-5 lg:px-28">
                <div className="w-[30%] h-screen flex justify-start top-10 items-center text-white px-2 md:px-10 sticky flex-col min-w-[200px]">
                    {currentSection === "image" && config.image_paths[currentImageSelect] ? (
                        <>
                            <img src={`${_config.api_server_uri}${Path.Svg.id.replace(/:svgid/, config.image_paths[currentImageSelect].id)}`} alt="" />
                            <span className="py-3">{config.image_paths[currentImageSelect].name}</span>
                        </>
                    ) : null}
                    {currentSection === "svg" && svgs[currentSvgSelect] ? (
                        <>
                            <img src={`${_config.api_server_uri}${Path.Svg.id.replace(/:svgid/, svgs[currentSvgSelect].id)}`} alt="" />
                            <span className="py-3">{svgs[currentSvgSelect].name}</span>
                        </>
                    ) : null}
                </div>
                <div className="w-[70%] pt-8">
                    <div>
                        {config.image_paths.map((svg, index) => {
                            return (
                                <div draggable="true" className="flex justify-center items-center w-full my-2 text-white" key={index}>
                                    <div
                                        className={`w-full cursor-pointer h-14 bg-gray-700 ${
                                            index === currentImageSelect ? "bg-gray-600" : "hover:bg-gray-600"
                                        } rounded-lg grid grid-cols-2  place-items-center`}
                                        onMouseEnter={() => {
                                            setCurrentImageSelect(index);
                                            setCurrentSection("image");
                                        }}
                                    >
                                        <div>{svg.name}</div>
                                        <div className="flex justify-end items-center w-full pr-2">
                                            <button
                                                className={`mx-5 text-center w-full h-10 flex justify-start items-center text-[0.6rem] md:text-sm  my-1 cursor-pointer rounded-lg  px-4 py-2 ${
                                                    config.default_image_select === index ? "bg-blue-700/60" : "hover:bg-blue-600 bg-blue-700"
                                                }`}
                                                type="button"
                                                onClick={() => {
                                                    onChange({ ...config, default_image_select: index });
                                                }}
                                            >
                                                <svg className="w-5 h-5 mr-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="m6.072 10.072 2 2 6-4m3.586 4.314.9-.9a2 2 0 0 0 0-2.828l-.9-.9a2 2 0 0 1-.586-1.414V5.072a2 2 0 0 0-2-2H13.8a2 2 0 0 1-1.414-.586l-.9-.9a2 2 0 0 0-2.828 0l-.9.9a2 2 0 0 1-1.414.586H5.072a2 2 0 0 0-2 2v1.272a2 2 0 0 1-.586 1.414l-.9.9a2 2 0 0 0 0 2.828l.9.9a2 2 0 0 1 .586 1.414v1.272a2 2 0 0 0 2 2h1.272a2 2 0 0 1 1.414.586l.9.9a2 2 0 0 0 2.828 0l.9-.9a2 2 0 0 1 1.414-.586h1.272a2 2 0 0 0 2-2V13.8a2 2 0 0 1 .586-1.414Z"
                                                    />
                                                </svg>
                                                select as default
                                            </button>

                                            <div
                                                className={`w-14 h-10 flex justify-center items-center cursor-pointer rounded-lg bg-red-600 hover:bg-red-700`}
                                                onClick={() => {
                                                    const imageindex = config.image_paths.findIndex((image) => image.id === svg.id);
                                                    const image_paths = [...config.image_paths];
                                                    image_paths.splice(imageindex, 1);
                                                    setCurrentImageSelect(0);
                                                    onChange({ ...config, image_paths });
                                                }}
                                            >
                                                <div className="w-full  flex justify-center items-center">
                                                    <svg
                                                        className={`w-5 h-5 text-gray-800 dark:text-white transition-transform rotate-45 `}
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 18 18"
                                                    >
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="w-full h-[1px] rounded-sm bg-white mt-5"></div>
                    <div className="mt-5">
                        {svgs.map((svg, index) => {
                            const isUse = !!config.image_paths.find((image) => image.id === svg.id);
                            if (isUse) return null;
                            return (
                                <div className="flex justify-center items-center w-full my-2 text-white" key={index}>
                                    <div
                                        className={`w-full cursor-pointer h-14 bg-gray-700 ${
                                            index === currentSvgSelect ? "bg-gray-600" : "hover:bg-gray-600"
                                        } rounded-lg grid grid-cols-2 place-items-center`}
                                        onMouseEnter={() => {
                                            setcurrentSvgSelect(index);
                                            setCurrentSection("svg");
                                        }}
                                    >
                                        <div className="w-full flex justify-start px-5">{svg.name}</div>
                                        <div className="flex justify-end w-full pr-2">
                                            <div
                                                className={`w-10 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3 bg-blue-600 hover:bg-blue-700`}
                                                onClick={() => {
                                                    const image_paths = [...config.image_paths];
                                                    image_paths.push(svg);
                                                    onChange({ ...config, image_paths });
                                                }}
                                            >
                                                <div className="w-full flex justify-center items-center">
                                                    <svg
                                                        className={`w-5 h-5 text-gray-800 dark:text-white transition-transform  `}
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 18 18"
                                                    >
                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};
interface TConfigToolsProps {
    config: Config;
    onChange: (config: Config) => void;
}

const ConfigTools = (props: TConfigToolsProps) => {
    const { config, onChange } = props;
    const [showtools, setshowtools] = useState(false);
    if (!showtools)
        return (
            <div className="w-full">
                <button
                    onClick={() => {
                        setshowtools((showtools) => !showtools);
                        window.scrollTo({
                            behavior: "smooth",
                            left: 0,
                            top: 0,
                        });
                    }}
                    type="button"
                    className="flex text-white  focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                >
                    config tools
                </button>
            </div>
        );

    return createPortal(
        <div className="w-full h-auto min-h-screen absolute top-0 left-0 bg-gray-800 flex justify-center flex-col items-start pt-20 z-40">
            <div className="sticky top-0 right-0 w-36 h-36 flex items-center justify-center z-30">
                <div className="flex items-center justify-center" onClick={() => setshowtools(false)}>
                    <div className="m-5">
                        <div className="flex p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer">
                            <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full px-5 lg:px-28 flex-col">
                {config.tools.map((tool, index) => {
                    if (tool.action === "bucket" && !config.use_image) return null;
                    // if (index >= 1 && !showTools) return null;
                    return (
                        <div className="w-full  dark:bg-gray-800 rounded-lg h-auto py-2 " key={index}>
                            <div className="w-full h-10 flex justify-center items-center mb-2">
                                <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-10 text-gray-400">
                                    <div className="w-1/2 h-full flex justify-center items-center">action</div>
                                    <div className="w-1/2 flex justify-center items-center">{tool.action}</div>
                                </div>
                            </div>
                            {tool.action === "marker" || tool.action === "eraser" ? (
                                <div className="w-full h-auto flex items-center justify-center mb-2">
                                    <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                        <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400">size</div>
                                        <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                            <div className="h-20 flex flex-col items-center justify-center">
                                                <div className="py-2">
                                                    <input
                                                        type="number"
                                                        value={tool.size}
                                                        onChange={(event) => {
                                                            const tools: Tools[] = [...config.tools];
                                                            const currntnTools = tools[index] as typeof tool;
                                                            currntnTools.size = event.target.valueAsNumber;
                                                            onChange({ ...config, tools });
                                                        }}
                                                        className="text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="w-full h-auto flex items-center justify-center mb-2">
                                <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                    <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">icon</div>
                                    <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                        <div className="h-40 flex flex-col items-center justify-center">
                                            <div className="py-2">
                                                <input
                                                    type="text"
                                                    value={tool.icon}
                                                    className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                    onChange={(event) => {
                                                        const tools: Tools[] = [...config.tools];
                                                        const currntnTools = tools[index] as typeof tool;
                                                        currntnTools.icon = event.target.value;
                                                        onChange({ ...config, tools });
                                                    }}
                                                />
                                            </div>
                                            <div className="w-24 h-24 flex justify-center items-center bg-white rounded-full">
                                                <img src={tool.icon} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full h-auto flex justify-center items-center">
                                <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                    <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">text</div>
                                    <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                        <div className="py-2">
                                            <input
                                                type="text"
                                                value={tool.text}
                                                className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                onChange={(event) => {
                                                    const tools: Tools[] = [...config.tools];
                                                    const currntnTools = tools[index] as typeof tool;
                                                    currntnTools.text = event.target.value;
                                                    onChange({ ...config, tools });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {tool.action === "link" && (
                                <div className="w-full h-auto mt-2 flex justify-center items-center">
                                    <div className="h-auto flex flex-row  w-11/12 bg-gray-700 rounded-md">
                                        <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">link</div>
                                        <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                            <div className="py-2">
                                                <input
                                                    type="text"
                                                    value={tool.href}
                                                    className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                    onChange={(event) => {
                                                        const tools: Tools[] = [...config.tools];
                                                        const currntnTools = tools[index] as typeof tool;
                                                        currntnTools.href = event.target.value;
                                                        onChange({ ...config, tools });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="w-full h-auto mt-2 flex justify-center items-center mb-2">
                                <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                    <label className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 select-none" htmlFor={`show-checkbox-${index}`}>
                                        show
                                    </label>
                                    <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                        <div className="py-5 flex justify-center items-center">
                                            <input
                                                id={`show-checkbox-${index}`}
                                                checked={tool.show}
                                                type="checkbox"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                onChange={(event) => {
                                                    const tools: Tools[] = [...config.tools];
                                                    const currntnTools = tools[index] as typeof tool;
                                                    currntnTools.show = event.target.checked;
                                                    onChange({ ...config, tools });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {tool.action === "marker" ||
                                tool.action === "eraser" ||
                                (tool.action === "bucket" && (
                                    <>
                                        <div className="w-full h-auto flex items-center justify-center">
                                            <div className="w-full h-auto flex items-center justify-center mb-2">
                                                <div className="h-auto flex flex-row w-11/12 bg-gray-700 rounded-md">
                                                    <div className="w-1/2 h-auto flex justify-center items-center text-sm text-gray-400 ">cursor icon</div>
                                                    <div className="w-1/2 flex justify-center items-center h-auto px-5 gap-3">
                                                        <div className="h-40 flex flex-col items-center justify-center">
                                                            <div className="py-2">
                                                                <input
                                                                    type="text"
                                                                    value={tool.iconPath}
                                                                    className="text-sm h-10 font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                    onChange={(event) => {
                                                                        const tools: Tools[] = [...config.tools];
                                                                        const currntnTools = tools[index] as typeof tool;
                                                                        currntnTools.iconPath = event.target.value;
                                                                        onChange({ ...config, tools });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="w-24 h-24 flex justify-center items-center bg-white">
                                                                <img src={tool.iconPath} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full h-auto flex justify-center mb-2">
                                            <div className="flex justify-center items-center w-11/12 h-20 bg-gray-700 rounded-md">
                                                <button
                                                    type="button"
                                                    className={` text-center w-6/12 h-10 flex justify-center items-center  cursor-pointer rounded-lg ${
                                                        tool.active ? "bg-blue-600/50 text-gray-400" : "text-white hover:bg-blue-600 bg-blue-700"
                                                    }`}
                                                    onClick={() => {
                                                        const tools: Tools[] = [...config.tools];
                                                        tools.forEach((tool) => ("active" in tool ? (tool.active = false) : null));
                                                        const currntnTools = tools[index] as typeof tool;
                                                        currntnTools.active = true;
                                                        onChange({ ...config, tools });
                                                    }}
                                                >
                                                    active
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ))}

                            <div className="w-full h-auto mt-2 flex justify-center items-center mb-2">
                                <div className="h-auto flex justify-end flex-row w-11/12  rounded-md">
                                    <div
                                        className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                        onClick={() => {
                                            const tools: Tools[] = [...config.tools];
                                            tools.splice(index, 1);
                                            onChange({ ...config, tools });
                                        }}
                                    >
                                        <div className="w-full flex justify-center items-center">
                                            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>,
        document.body
    );
};
const ConfigPage = (props: { config: Config; onChange: (config: Config) => void }) => {
    const { config, onChange } = props;

    return (
        <>
            {/*  colors palette */}
            <div className="mt-2">
                <h3 className="text-sm font-medium text-gray-400 mb-3 underline">colors palette</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full ">
                    {config.colors_palette.map((color, index) => {
                        return (
                            <div className="flex justify-start items-center w-full" key={index}>
                                <div className="w-1/3 overflow-hidden rounded-xl">
                                    <input
                                        className="bg-transparent rounded-none w-full h-10 p-0 border-0"
                                        type="color"
                                        value={color}
                                        onChange={(event) => {
                                            const colors_palette = config.colors_palette;
                                            colors_palette[index] = event.target.value;

                                            onChange({ ...config, colors_palette });
                                        }}
                                    />
                                </div>

                                <div className="w-1/4 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3">
                                    <input
                                        type="text"
                                        value={color}
                                        onChange={(event) => {
                                            const colors_palette = config.colors_palette;
                                            colors_palette[index] = event.target.value;

                                            onChange({ ...config, colors_palette });
                                        }}
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full h-10  px-2"
                                    />
                                </div>
                                <div
                                    className={`text-center text-sm w-1/3 h-10 flex justify-center items-center  cursor-pointer rounded-lg ml-3 text-white ${
                                        config.default_colors_select === index ? "bg-blue-700/60" : "hover:bg-blue-600 bg-blue-700"
                                    }`}
                                    onClick={(event) => {
                                        onChange({ ...config, default_colors_select: index });
                                    }}
                                >
                                    select as default
                                </div>
                                <div
                                    className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                    onClick={() => {
                                        const colors_palette = [...config.colors_palette];
                                        let default_colors_select = config.default_colors_select;
                                        const currentColorSelect = colors_palette[default_colors_select + 1];

                                        let newDefault_colors_select = config.default_colors_select;
                                        if (!currentColorSelect) newDefault_colors_select = config.default_colors_select = 0;
                                        colors_palette.splice(index, 1);
                                        onChange({
                                            ...config,
                                            default_colors_select: newDefault_colors_select,
                                            colors_palette,
                                        });
                                    }}
                                >
                                    <div className="w-full flex justify-center items-center">
                                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="w-full mt-3 flex justify-end">
                    <div
                        className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                        onClick={() => {
                            const colors_palette = [...config.colors_palette];
                            colors_palette.push("#FFFFFF");
                            onChange({ ...config, colors_palette });
                        }}
                    >
                        <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                        </svg>
                    </div>
                </div>
            </div>
            {/* canvas */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">canvas</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    <div className="w-full">
                        <label htmlFor="paint-config-canvas-scale-rasio">
                            <span>scale rasio</span>
                            <input
                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                type="number"
                                name="paint-config-canvas-scale-rasio"
                                id="paint-config-canvas-scale-rasio"
                                value={config.canvas.scale_raio}
                                onChange={(event) => {
                                    const canvas = { ...config.canvas };
                                    canvas.scale_raio = event.target.valueAsNumber;
                                    if (canvas.scale_raio > 0) {
                                        onChange({ ...config, canvas });
                                    }
                                }}
                            />
                        </label>
                    </div>
                    <div className="w-full">
                        <label htmlFor="paint-config-canvas-color">
                            <span>color</span>
                            <input
                                id="paint-config-canvas-color"
                                name="paint-config-canvas-color"
                                className="bg-transparent rounded-none w-full h-10 p-0 border-0"
                                type="color"
                                value={config.canvas.color}
                                onChange={(event) => {
                                    const canvas = { ...config.canvas };
                                    canvas.color = event.target.value;
                                    onChange({ ...config, canvas });
                                }}
                            />
                        </label>
                    </div>
                    <div className="w-full text-base">
                        <span>default size</span>
                        <div className="pl-5">
                            <div className="flex justify-center w-full mt-2 text-base">
                                <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-default-size-width">
                                    width
                                    <input
                                        id="paint-config-default-size-width"
                                        type="number"
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                        step="100"
                                        max="1000"
                                        min="100"
                                        value={config.canvas.default_size.width}
                                        onChange={(event) => {
                                            const canvas = { ...config.canvas };
                                            canvas.default_size.width = event.target.valueAsNumber;
                                            onChange({ ...config, canvas });
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="flex justify-center w-full mt-2 text-base">
                                <label className="font-medium text-gray-400 w-full" htmlFor="paint-config-default-size-height">
                                    height
                                    <input
                                        id="paint-config-default-size-height"
                                        type="number"
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                        step="100"
                                        max="1000"
                                        min="100"
                                        value={config.canvas.default_size.height}
                                        onChange={(event) => {
                                            const canvas = { ...config.canvas };
                                            canvas.default_size.height = event.target.valueAsNumber;
                                            onChange({ ...config, canvas });
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* eraser */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">eraser</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    <div className="w-full">
                        <label htmlFor="paint-config-eraser-size">
                            <span>size</span>
                            <input
                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                type="number"
                                name="paint-config-eraser-size"
                                id="paint-config-eraser-size"
                                value={config.eraser.size}
                                onChange={(event) => {
                                    const eraser = { ...config.eraser };
                                    eraser.size = event.target.valueAsNumber;

                                    onChange({ ...config, eraser });
                                }}
                            />
                        </label>
                    </div>
                    <div className="w-full">
                        <label htmlFor="paint-config-eraser-color">
                            <span>color</span>
                            <input
                                className="bg-transparent rounded-none w-full h-10 p-0 border-0"
                                type="color"
                                id="paint-config-eraser-color"
                                value={config.eraser.color}
                                onChange={(event) => {
                                    const eraser = { ...config.eraser };
                                    eraser.color = event.target.value;

                                    onChange({ ...config, eraser });
                                }}
                            />
                        </label>
                    </div>
                </div>
            </div>
            {/* tools */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">tools</h3>
                <ConfigTools config={config} onChange={onChange} />
            </div>
            {/* images */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">images</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    <div className="w-full">
                        <label className="relative inline-flex items-center mb-5 cursor-pointer" htmlFor="paint-config-use-image">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                id="paint-config-use-image"
                                value=""
                                checked={config.use_image}
                                onChange={(event) => {
                                    onChange({ ...config, use_image: event.target.checked });
                                }}
                            />
                            <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium ">use</span>
                        </label>
                    </div>
                    <div className="w-full">
                        <label className="relative inline-flex items-center mb-5 cursor-pointer" htmlFor="paint-config-use-multi-image">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                value=""
                                id="paint-config-use-multi-image"
                                checked={config.use_multi_image}
                                onChange={(event) => {
                                    onChange({ ...config, use_multi_image: event.target.checked });
                                }}
                            />
                            <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium ">use multi</span>
                        </label>
                    </div>
                    <ImageList config={config} onChange={onChange} />
                </div>
            </div>
            {/* svg_filter_colors*/}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">svg filter colors</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    {config.svg_filter_colors.map((color, index) => {
                        return (
                            <div className="w-full flex" key={index}>
                                <div className="w-full">
                                    <input
                                        type="text"
                                        onChange={(event) => {
                                            const svg_filter_colors = [...config.svg_filter_colors];
                                            svg_filter_colors[index] = event.target.value;
                                            onChange({ ...config, svg_filter_colors });
                                        }}
                                        value={color}
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 "
                                    />
                                </div>
                                <div
                                    className="w-14 ml-2 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg hover:bg-red-700"
                                    onClick={() => {
                                        const svg_filter_colors = [...config.svg_filter_colors];
                                        svg_filter_colors.splice(index, 1);
                                        onChange({ ...config, svg_filter_colors });
                                    }}
                                >
                                    <div className="w-full flex justify-center items-center">
                                        <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="w-full mt-3 flex justify-end">
                        <div
                            className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                            onClick={() => {
                                const svg_filter_colors = [...config.svg_filter_colors];
                                svg_filter_colors.push("#FFFFFF");
                                onChange({ ...config, svg_filter_colors });
                            }}
                        >
                            <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {/* intro */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">intro</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    <div className="w-full">
                        <label className="relative inline-flex items-center cursor-pointer" htmlFor="paint-config-intro-show">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                value=""
                                id="paint-config-intro-show"
                                checked={config.intro.state}
                                onChange={(event) => {
                                    const intro = { ...config.intro };
                                    intro.state = event.target.checked;
                                    onChange({ ...config, intro });
                                }}
                            />
                            <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium ">use</span>
                        </label>
                    </div>
                    <div className="w-full">
                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-intro-text">
                            text
                            <input
                                id="paint-config-intro-text"
                                type="text"
                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                step="1"
                                max="1000"
                                min="1"
                                value={config.intro.text}
                                onChange={(event) => {
                                    const intro = { ...config.intro };
                                    intro.text = event.target.value;
                                    onChange({ ...config, intro });
                                }}
                            />
                        </label>
                    </div>
                    <div className="w-full mt-2">
                        <span>animation</span>
                        <div className="pl-5">
                            <div className="flex justify-center w-full mt-2 text-base">
                                <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-intro-animation-step-top">
                                    top step
                                    <input
                                        id="paint-config-intro-animation-step-top"
                                        type="number"
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                        step="1"
                                        max="1000"
                                        min="1"
                                        value={config.intro.animation_step_top}
                                        onChange={(event) => {
                                            const intro = { ...config.intro };
                                            intro.animation_step_top = event.target.valueAsNumber;
                                            onChange({ ...config, intro });
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="flex justify-center w-full mt-2 text-base">
                                <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-intro-animation-step-opacity">
                                    opacity step
                                    <input
                                        id="paint-config-intro-animation-step-opacity"
                                        type="number"
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                        step="0.001"
                                        max="1"
                                        min="0"
                                        value={config.intro.animation_step_opacity}
                                        onChange={(event) => {
                                            const intro = { ...config.intro };
                                            intro.animation_step_opacity = event.target.valueAsNumber;
                                            onChange({ ...config, intro });
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* title */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">title</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    <span className="text-[0.75rem] w-full">{`{{name}} to replace by paint name`}</span>
                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-title-home">
                        home
                        <input
                            id="paint-config-title-home"
                            type="text"
                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                            step="1"
                            max="1000"
                            min="1"
                            value={config.title.home}
                            onChange={(event) => {
                                const title = { ...config.title };
                                title.home = event.target.value;
                                onChange({ ...config, title });
                            }}
                        />
                    </label>
                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-title-gallery">
                        gallery
                        <input
                            id="paint-config-title-gallery"
                            type="text"
                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                            step="1"
                            max="1000"
                            min="1"
                            value={config.title.gallery}
                            onChange={(event) => {
                                const title = { ...config.title };
                                title.gallery = event.target.value;
                                onChange({ ...config, title });
                            }}
                        />
                    </label>
                </div>
            </div>
            {/* interatif */}
            <div className="mt-2 text-gray-400">
                <h3 className="text-sm font-medium mb-3 underline">interatif</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                    <div className="w-full text-base">
                        <div className="w-full my-2">
                            <label className="relative inline-flex items-center cursor-pointer" htmlFor="paint-config-interatif-state">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    value=""
                                    id="paint-config-interatif-state"
                                    checked={config.interatif.state}
                                    onChange={(event) => {
                                        const interatif = { ...config.interatif };
                                        interatif.state = event.target.checked;
                                        onChange({ ...config, interatif });
                                    }}
                                />
                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium ">use</span>
                            </label>
                        </div>
                        <div>
                            <span>row</span>
                            <div className="pl-5">
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <div className="w-full">
                                        <label className="relative inline-flex items-center cursor-pointer" htmlFor="paint-config-interatif-row">
                                            <input
                                                type="checkbox"
                                                className="sr-only peer"
                                                value=""
                                                id="paint-config-interatif-row"
                                                checked={config.interatif.row}
                                                onChange={(event) => {
                                                    const interatif = { ...config.interatif };
                                                    interatif.row = event.target.checked;
                                                    onChange({ ...config, interatif });
                                                }}
                                            />
                                            <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                            <span className="ml-3 text-sm font-medium ">use</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-interatif-default-row">
                                        number
                                        <input
                                            id="paint-config-interatif-default-row"
                                            type="number"
                                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                            step="1"
                                            min="1"
                                            value={config.interatif.default_row}
                                            onChange={(event) => {
                                                const interatif = { ...config.interatif };
                                                interatif.default_row = event.target.valueAsNumber;
                                                onChange({ ...config, interatif });
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <span>default size</span>
                            <div className="pl-5">
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-interatif-default-size-width">
                                        width
                                        <input
                                            id="paint-config-interatif-default-size-width"
                                            type="number"
                                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                            step="100"
                                            max="5000"
                                            min="100"
                                            value={config.interatif.default_size.width}
                                            onChange={(event) => {
                                                const interatif = { ...config.interatif };
                                                interatif.default_size.width = event.target.valueAsNumber;
                                                onChange({ ...config, interatif });
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-interatif-default-size-height">
                                        height
                                        <input
                                            id="paint-config-interatif-default-size-height"
                                            type="number"
                                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                            step="100"
                                            max="5000"
                                            min="100"
                                            value={config.interatif.default_size.height}
                                            onChange={(event) => {
                                                const interatif = { ...config.interatif };
                                                interatif.default_size.height = event.target.valueAsNumber;
                                                onChange({ ...config, interatif });
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <span>render size</span>
                            <div className="pl-5">
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-interatif-render-size-width">
                                        width
                                        <input
                                            id="paint-config-interatif-render-size-width"
                                            type="number"
                                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                            step="100"
                                            max="5000"
                                            min="100"
                                            value={config.interatif.render.size.width}
                                            onChange={(event) => {
                                                const interatif = { ...config.interatif };
                                                interatif.render.size.width = event.target.valueAsNumber;
                                                onChange({ ...config, interatif });
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-interatif-default-size-height">
                                        height
                                        <input
                                            id="paint-config-interatif-default-size-height"
                                            type="number"
                                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                            step="100"
                                            max="5000"
                                            min="100"
                                            value={config.interatif.render.size.height}
                                            onChange={(event) => {
                                                const interatif = { ...config.interatif };
                                                interatif.render.size.height = event.target.valueAsNumber;
                                                onChange({ ...config, interatif });
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfigPage;
