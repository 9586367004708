import React from "react";
import PaintCard from "../paint-card";

const Paint = (props: { user: User }) => {
    const { user } = props;
    return (
        <div>
            {user.paints.length === 0 ? (
                <div className="w-full h-auto dark:text-white text-black flex justify-center items-center p-16 ">no application</div>
            ) : (
                <div className="mt-5">
                    <PaintCard paints={user.paints} />
                </div>
            )}
        </div>
    );
};

export default Paint;
