import React from 'react';
import PlanCard from '../plan-card';

const plan = (props: { user: User }) => {
    const { user } = props;
    if (user.role !== "admin") return null
    if (user.plans.length === 0) return <div className="w-full h-auto text-white flex justify-center items-center p-16">
        no plan
    </div>;

    return (
        <div>
            <div className='mt-5'>
                {<PlanCard plans={user.plans} />}
            </div>
        </div>
    );
};

export default plan;