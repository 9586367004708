import { useState } from "react"
export const useMessage = () => {
    const [message, setMessage] = useState<{ content: string, type: "error" | "success" } | null>(null);
    const createMessage = (message: { content: string, type: "error" | "success" }) => {
        setMessage(message);
    };
    const closeMessage = () => {
        setMessage(null);
    }
    const element = <>
        {message ? <div className="w-full flex justify-center items-center mt-5">
            <div className={`flex p-4 rounded-lg ${message.type === "error" ? "bg-red-100  dark:bg-red-200" : "bg-green-100  dark:bg-green-200"}`} role="alert">
                <svg aria-hidden="true" className={`flex-shrink-0 w-5 h-5 ${message.type === "error" ? "text-red-700 dark:text-red-800" : "text-green-700 dark:text-green-800"}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Info</span>
                <div className={`ml-3 text-sm font-medium ${message.type === "error" ? "text-red-700 dark:text-red-800" : "text-green-700 dark:text-green-800"}`}>
                    {message.content}
                </div>
                <button
                    type="button"
                    className={`ml-auto -mx-1.5 -my-1.5  rounded-lg focus:ring-2  inline-flex h-8 w-8 ${message.type === "error" ? "bg-red-100 text-red-500 focus:ring-red-400 p-1.5 hover:bg-red-200 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300" : "bg-green-100 text-green-500 focus:ring-green-400 p-1.5 hover:bg-green-200 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300"}`}
                    aria-label="Close"
                    onClick={() => closeMessage()}
                >
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>
        </div>
            : null}

    </>;
    return {
        element: element,
        closeMessage,
        createMessage
    }
};