import React from "react";
import { NavLink } from "react-router-dom";

const Plan = (props: { name: string; price: number; options: string[]; description: string; id: string }) => {
    const { name, description, options, price, id } = props;
    return (
        <div className="my-7">
            <div className="flex flex-col p-6 mx-auto max-w-lg text-center  rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white">
                <h3 className="mb-4 text-2xl font-semibold">{name}</h3>
                <p className="font-light  sm:text-lg text-gray-400">{description}</p>
                <div className="flex justify-center items-baseline my-8">
                    <span className="mr-2 text-5xl font-extrabold">${price}</span>
                    <span className=" text-gray-400">/event</span>
                </div>
                <ul className="mt-8 space-y-4 text-left h-56">
                    {options.map((option, index) => (
                        <li key={index} className="flex items-center space-x-3">
                            <svg className="flex-shrink-0 w-5 h-5  text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span>{option}</span>
                        </li>
                    ))}
                </ul>
                <NavLink
                    to={`/plans/${id}`}
                    className="bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white  focus:ring-primary-900"
                >
                    Get started
                </NavLink>
            </div>
        </div>
    );
};

export default Plan;
