import axios from "axios";
import config from "../../config";
import { useEffect, useState } from "react";
import { Path } from "../../constants";
export const useAuth = () => {
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [isloading, setIsloading] = useState<boolean>(true);

    const getUser = async () => {
        try {
            const localToken = localStorage.getItem("token");
            if (localToken) {
                const responce = await axios.post(`${config.api_server_uri}${Path.Auth.index}`, {}, { headers: { Authorization: localToken } });
                if (responce.status === 200 && responce.data) {
                    if (responce.data.user.role === "admin") {
                        setUser({
                            ...responce.data.user,
                            paints: responce.data.paints,
                            plans: responce.data.plans,
                        });
                    } else {
                        setUser(responce.data.user);
                    }

                    setToken(localToken);
                } else localStorage.removeItem("token");
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsloading(false);
        }
    };
    const logout = () => {
        setUser(null);
    };
    useEffect(() => {
        getUser();
    }, []);
    useEffect(() => {}, [user]);

    return { token, user, isloading, logout, getUser };
};
