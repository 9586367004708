import { TypeOf, z } from "zod";
import { zTool } from "./tools";
export const zConfig = z.object(
    {
        base_uri: z.string({ invalid_type_error: "base_uri is type of string", required_error: "base_uri is required" }),
        socket_server_uri: z.string({ invalid_type_error: "socket_server_uri is type of string", required_error: "socket_server_uri is required" }),
        api_server_uri: z.string({ invalid_type_error: "api_server_uri is type of string", required_error: "api_server_uri is required" }),
        colors_palette: z.string({ invalid_type_error: "colors_palette is type of string array", required_error: "colors_palette is required" }).array(),
        default_colors_select: z.number({ invalid_type_error: "default_colors_select is type of number", required_error: "default_colors_select is required" }),
        undo: z.object(
            {
                icon: z.string({ invalid_type_error: "undo.icon is type of string", required_error: "undo.icon is required" }),
                size: z.number({ invalid_type_error: "undo.size is type of string", required_error: "undo.size is required" }),
            },
            { required_error: "undo is requred" }
        ),
        tools: zTool.array(),
        default_tools_select: z.number({ invalid_type_error: "default_tools_select is type of number", required_error: "default_tools_select is required" }),
        // canvas color and size
        canvas: z.object({
            default_size: z.object(
                {
                    width: z.number({ invalid_type_error: "canvas.default_size.width is type of number", required_error: "canvas.default_size.height is required" }),
                    height: z.number({ invalid_type_error: "canvas.default_size.width is type of number", required_error: "canvas.default_size.height is required" }),
                },
                { invalid_type_error: "canvas.default_size is type of object", required_error: "canvas.default_size is required" }
            ),
            minsize: z.number({ invalid_type_error: "canvas.minsize is type of number", required_error: "canvas.minsize is required" }), // min size on width
            scale_raio: z.number({ invalid_type_error: "canvas.scale_raio is type of number", required_error: "canvas.scale_raio is required" }),
            color: z.string({ invalid_type_error: "canvas.color is type of string", required_error: "canvas.color is required" }),
            render: z.object(
                {
                    size: z.object(
                        {
                            width: z.number({ invalid_type_error: "canvas.render.size.width is type of number", required_error: "canvas.render.size.width  is required" }),
                            height: z.number({ invalid_type_error: "canvas.render.size.height is type of number", required_error: "canvas.render.size.height  is required" }),
                        },
                        { invalid_type_error: "canvas.render.size is type of object", required_error: "canvas.render.size is required" }
                    ),
                },
                { invalid_type_error: "canvas.render is type of object", required_error: "canvas.render is required" }
            ),
        }),
        eraser: z.object(
            {
                size: z.number({ invalid_type_error: "eraser.size is type of number", required_error: "eraser.size is required" }),
                color: z.string({ invalid_type_error: "eraser.color is type of string", required_error: "eraser.color is required" }),
            },
            { invalid_type_error: "eraser is type of object", required_error: "eraser is required" }
        ),
        // image and path
        use_image: z.boolean({ invalid_type_error: "use_image is type of boolean", required_error: "use_image is required" }),
        use_multi_image: z.boolean({ invalid_type_error: "use_multi_image is type of boolean", required_error: "use_multi_image is required" }),
        image_paths: z.object({ id: z.string(), name: z.string() }, { invalid_type_error: "image_paths is type of string array", required_error: "image_paths is required" }).array(),
        default_image_select: z.number({ invalid_type_error: "default_image_select is type of number", required_error: "default_image_select is required" }),
        svg_filter_colors: z.string({ invalid_type_error: "svg_filter_colors is type of string array", required_error: "svg_filter_colors is required" }).array(),
        key_input: z.object(
            {
                undo: z.number({ invalid_type_error: "key_input.undo is type of number", required_error: "key_input.undo is required" }).nullable(),
                del: z.number({ invalid_type_error: "key_input.del is type of number", required_error: "key_input.del is required" }).nullable(),
            },
            { invalid_type_error: "key_input is type of object", required_error: "key_input is required" }
        ),
        intro: z.object(
            {
                animation_step_top: z.number({ invalid_type_error: "intro.animation_step_top is type of number", required_error: "intro.animation_step_top is required" }),
                animation_step_opacity: z.number({ invalid_type_error: "intro.animation_step_opacity is type of number", required_error: "intro.animation_step_opacity is required" }),
                text: z.string({ invalid_type_error: "intro.text is type of string", required_error: "intro.text is required" }),
                state: z.boolean({ invalid_type_error: "intro.state is type of boolean", required_error: "intro.state is required" }),
            },
            { invalid_type_error: "intro is type of object", required_error: "intro is required" }
        ),
        log: z.boolean({ invalid_type_error: "log is type of boolean", required_error: "log is required" }),
        credit: z.object(
            {
                image: z.string({
                    invalid_type_error: "credit.image is type of string",
                    required_error: "credit.image is required",
                }),
                size: z.object(
                    {
                        width: z.number({
                            invalid_type_error: "credit.width is type of number",
                            required_error: "credit.width is required",
                        }),
                        height: z.number({
                            invalid_type_error: "credit.height is type of number",
                            required_error: "credit.height is required",
                        }),
                    },
                    {
                        invalid_type_error: "credit.size is type of object",
                        required_error: "credit.size is required",
                    }
                ),
                position: z.object(
                    {
                        x: z.number({
                            invalid_type_error: "credit.position.x is type of number",
                            required_error: "credit.position.x is required",
                        }),
                        y: z.number({
                            invalid_type_error: "credit.position.y is type of number",
                            required_error: "credit.position.y is required",
                        }),
                    },
                    {
                        invalid_type_error: "credit.position.y is type of object",
                        required_error: "credit.position.y is required",
                    }
                ),
                opacity: z.number({
                    invalid_type_error: "credit.opacity is type of number",
                    required_error: "credit.opacity is required",
                }),
                state: z.boolean({
                    invalid_type_error: "credit.state is type of boolean",
                    required_error: "credit.state is required",
                }),
            },
            { invalid_type_error: "credit is type of object", required_error: "credit is required" }
        ),
        gallery_default_post_uri: z.string({
            invalid_type_error: "gallery_default_post_uri is type of string",
            required_error: "gallery_default_post_uri is required",
        }),
        default_gallery_name: z.string({
            invalid_type_error: "default_gallery_name is type of string",
            required_error: "default_gallery_name is required",
        }),
        message_time_out: z.number({
            invalid_type_error: "message_time_out is type of string",
            required_error: "message_time_out is required",
        }),
        title: z.object(
            {
                home: z.string({
                    invalid_type_error: "title.home is type of string",
                    required_error: "title.home is required",
                }),
                gallery: z.string({
                    invalid_type_error: "title.gallery is type of string",
                    required_error: "title.gallery is required",
                }),
            },
            {
                invalid_type_error: "title is type of object",
                required_error: "title is required",
            }
        ),
        live_draw: z.object(
            {
                state: z.boolean({
                    invalid_type_error: "live_draw.state is type of boolean",
                    required_error: "live_draw.state is required",
                }),
                max_users: z.number({
                    invalid_type_error: "live_draw.max_users is type of number",
                    required_error: "live_draw.state is required",
                }),
            },
            {
                invalid_type_error: "live_draw is type of object",
                required_error: "live_draw is required",
            }
        ),
        interatif: z.object(
            {
                state: z.boolean({
                    invalid_type_error: "interatif.state is type of boolean",
                    required_error: "interatif.state is required",
                }),
                row: z.boolean({
                    invalid_type_error: "interatif.row is type of boolean",
                    required_error: "interatif.row is required",
                }),
                default_row: z.number({
                    invalid_type_error: "interatif.default_row is type of number",
                    required_error: "interatif.default_row is required",
                }),
                default_size: z.object(
                    {
                        width: z.number({
                            invalid_type_error: "interatif.default_size.width is type of number",
                            required_error: "interatif.default_size.width is required",
                        }),
                        height: z.number({
                            invalid_type_error: "interatif.default_size.height is type of number",
                            required_error: "interatif.default_size.height is required",
                        }),
                    },
                    {
                        invalid_type_error: "interatif.default_size is type of object",
                        required_error: "interatif.default_size is required",
                    }
                ),
                gallery: z.object(
                    {
                        use: z.boolean({
                            invalid_type_error: "interatif.gallery.use.count is type of boolean",
                            required_error: "interatif.gallery.use.count is required",
                        }),
                        image_line: z.object(
                            {
                                count: z.number({
                                    invalid_type_error: "interatif.gallery.image_line.count is type of number",
                                    required_error: "interatif.gallery.image_line.count is required",
                                }),
                                state: z.boolean({
                                    invalid_type_error: "interatif.gallery.image_line.state is type of boolean",
                                    required_error: "interatif.gallery.image_line.state is required",
                                }),
                            },
                            { invalid_type_error: "interatif.gallery.image_line is type of object", required_error: "interatif.gallery.image_line is required" }
                        ),
                        show: z.object(
                            {
                                name: z.boolean({
                                    invalid_type_error: "interatif.gallery.show.name is type of boolean",
                                    required_error: "interatif.gallery.show.name is required",
                                }),
                                date: z.boolean({
                                    invalid_type_error: "interatif.gallery.show.date is type of boolean",
                                    required_error: "interatif.gallery.show.date is required",
                                }),
                            },
                            { invalid_type_error: "interatif.gallery.show is type of object", required_error: "interatif.gallery.show is required" }
                        ),
                        text: z.string({
                            invalid_type_error: "interatif.gallery.text is type of object",
                            required_error: "interatif.gallery.text is required",
                        }),
                    },
                    {
                        invalid_type_error: "interatif.gallery is type of object",
                        required_error: "interatif.gallery is required",
                    }
                ),
                render: z.object(
                    {
                        size: z.object(
                            {
                                width: z.number({
                                    invalid_type_error: "interatif.render.size.width is type of number",
                                    required_error: "interatif.render.size.width is required",
                                }),
                                height: z.number({
                                    invalid_type_error: "interatif.render.size.height is type of number",
                                    required_error: "interatif.render.size.height is required",
                                }),
                            },
                            {
                                invalid_type_error: "interatif.render.size is type of object",
                                required_error: "interatif.render.size is required",
                            }
                        ),
                    },
                    {
                        invalid_type_error: "interatif.render is type of object",
                        required_error: "interatif.render is required",
                    }
                ),
            },
            {
                invalid_type_error: "interatif is type of object",
                required_error: "interatif is required",
            }
        ),
        show_home: z.boolean({
            invalid_type_error: "show_home is type of boolean",
            required_error: "show_home is required",
        }),
        // questions: z
        //     .object(
        //         {
        //             label: z.string({
        //                 invalid_type_error: "questions.$.label is type of string",
        //                 required_error: "questions.$.label is required",
        //             }),
        //             value: z.string({
        //                 invalid_type_error: "questions.$.value is type of string",
        //                 required_error: "questions.$.value is required",
        //             }),
        //         },
        //         {
        //             invalid_type_error: "questions is type of array object",
        //             required_error: "questions is required",
        //         }
        //     )
        //     .array()
        //     .nullable()
        //     .optional(),
        gallery: z.object(
            {
                live_update: z.boolean({
                    invalid_type_error: "gallery.live_update is type of boolean",
                    required_error: "gallery.live_update is required",
                }),
                image_line: z.object(
                    {
                        count: z.number({
                            invalid_type_error: "gallery.image_line.count is type of number",
                            required_error: "gallery.image_line.count is required",
                        }),
                        state: z.boolean({
                            invalid_type_error: "gallery.image_line.state is type of boolean",
                            required_error: "gallery.image_line.state is required",
                        }),
                    },
                    {
                        invalid_type_error: "gallery.image_line is type of object",
                        required_error: "gallery.image_line is required",
                    }
                ),
                show: z.object(
                    {
                        name: z.boolean({
                            invalid_type_error: "gallery.show.name is type of boolean",
                            required_error: "gallery.show.name is required",
                        }),
                        date: z.boolean({
                            invalid_type_error: "gallery.show.name is type of boolean",
                            required_error: "gallery.show.date is required",
                        }),
                    },
                    {
                        invalid_type_error: "gallery.show is type of object",
                        required_error: "gallery.show is required",
                    }
                ),
                text: z.string({
                    invalid_type_error: "gallery.image_line.text is type of string",
                    required_error: "gallery.image_line.text is required",
                }),
            },
            {
                invalid_type_error: "gallery is type of object",
                required_error: "gallery is required",
            }
        ),
    },
    {
        required_error: "config is required",
        invalid_type_error: "config is type of object",
    }
);
