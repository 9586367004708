import React, { useContext, useState } from "react";
import { Errors } from "../../../../utils/error";
import axios from "axios";
import _config from "../../../../config";
import { Path } from "../../../../constants";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../../../contexts/auth";
import { TBIG_GALLERY_BASE } from "../../../../@types/big_gallery";
import { big_gallery_base } from "../../../../utils/default/big-gallery";
import { createPortal } from "react-dom";

interface TGalleryListProps {
    gallerys: string[];
    onChange: (gallerys: string[]) => void;
}
const GalleryList = (props: TGalleryListProps) => {
    const [showGalleryList, steShowGalleryList] = useState(false);
    const { gallerys, onChange } = props;
    const { user } = useContext(Auth);
    if (!user) return <div className="text-white">error to get user data</div>;

    if (!showGalleryList)
        return (
            <div className="w-full">
                <button
                    onClick={() => {
                        steShowGalleryList((showList) => !showList);
                    }}
                    type="button"
                    className="flex text-white  focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                >
                    show gallery list
                </button>
            </div>
        );

    return createPortal(
        <div className={`w-full min-h-screen absolute top-0 left-0 bg-gray-800 flex justify-center items-center pt-20 z-40`}>
            <div className="absolute top-[50px] right-[50px] w-[100px] h-[100px] ">
                <div
                    className="flex justify-center items-center h-[80px] w-[80px]  p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer"
                    onClick={() => steShowGalleryList(false)}
                >
                    <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>
            <div className="flex h-screen w-full px-5 lg:px-28 flex-col">
                {gallerys.length === 0 && <div className="text-white w-full h-10 flex justify-center items-center">no gallery select</div>}
                {gallerys.map((galleryid, index) => {
                    const paint = user.paints.find((paint) => paint.gallery.id === galleryid);
                    if (!paint) return null;
                    return (
                        <div className="flex justify-center items-center w-full my-2 text-white" key={index}>
                            <div className={`w-full cursor-pointer h-14 bg-gray-700 rounded-lg grid grid-cols-2 place-items-center`}>
                                <div className="w-full flex justify-start px-5">{paint.name}</div>
                                <div className="flex justify-end w-full pr-2">
                                    <div
                                        className={`w-10 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3 bg-red-600 hover:bg-red-700`}
                                        onClick={() => {
                                            const arrayData = [...gallerys];
                                            arrayData.splice(index, 1);
                                            onChange(arrayData);
                                        }}
                                    >
                                        <div className="w-full flex justify-center items-center">
                                            <svg
                                                className={`w-5 h-5 text-gray-800 dark:text-white transition-transform  rotate-45 `}
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 18 18"
                                            >
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="w-full h-[1px] bg-white"> </div>
                {user.paints.map((paint, index) => {
                    const isInGallery = gallerys.find((gallery) => gallery === paint.gallery.id);
                    if (isInGallery) return null;

                    return (
                        <div className="flex justify-center items-center w-full my-2 text-white" key={index}>
                            <div className={`w-full cursor-pointer h-14 bg-gray-700 rounded-lg grid grid-cols-2 place-items-center`}>
                                <div className="w-full flex justify-start px-5">{paint.name}</div>
                                <div className="flex justify-end w-full pr-2">
                                    <div
                                        className={`w-10 h-10 flex justify-center items-center cursor-pointer rounded-lg ml-3 bg-blue-600 hover:bg-blue-700`}
                                        onClick={() => {
                                            if (!paint.gallery.id) return;
                                            const arrayData = [...gallerys];
                                            arrayData.push(paint.gallery.id);
                                            onChange(arrayData);
                                        }}
                                    >
                                        <div className="w-full flex justify-center items-center">
                                            <svg
                                                className={`w-5 h-5 text-gray-800 dark:text-white transition-transform  `}
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 18 18"
                                            >
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>,
        document.body
    );
};

const Gallery = () => {
    const [gallery, setGallery] = useState<TBIG_GALLERY_BASE>(big_gallery_base);
    const { token } = useContext(Auth);

    const navigate = useNavigate();

    const handlerSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            if (!token) return toast.error("error to find token");
            const rs = await axios.post(`${_config.api_server_uri}${Path.Admin.Big_gallery.index}`, gallery, { headers: { Authorization: token } });
            if (rs.status !== 200) return toast.error(`${rs.data.message}`);
            navigate("/admin#big-gallery");
        } catch (error) {
            Errors.handler(error);
        }
    };
    return (
        <div className="bg-gray-800 min-h-screen flex justify-center items-start py-5 relative">
            <div className="absolute top-0 right-0 w-36 h-36 flex items-center justify-center z-30">
                <div className="flex items-center justify-center ">
                    <div className="m-5">
                        <div className="flex p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer" onClick={() => navigate("/admin#big-gallery")}>
                            <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={handlerSubmit} className="bg-gray-900 w-full md:w-1/2 p-5 h-auto py-10 rounded-none md:rounded-md">
                <div className="my-2">
                    <label htmlFor="paint-name" className="flex flex-col text-white">
                        <span className="cursor- ">name</span>
                        <input
                            type="text"
                            id="paint-name"
                            value={gallery.name}
                            className="border my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                            onChange={(event) => {
                                setGallery({ ...gallery, name: event.target.value });
                            }}
                        />
                    </label>
                </div>
                <GalleryList
                    gallerys={gallery.gallerys}
                    onChange={(gallerys) => {
                        setGallery((gallery) => {
                            gallery.gallerys = gallerys;
                            return { ...gallery };
                        });
                    }}
                />
                <div className="mt-2  text-gray-400">
                    <h3 className="text-sm font-medium mb-3 underline">config</h3>
                    <div className="flex justify-center items-center flex-col gap-2 w-full pl-3">
                        <div className="w-full text-base">
                            <span>image</span>
                            <div className="pl-5">
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-default-size-width">
                                        background_color
                                        <input
                                            id="paint-config-canvas-color"
                                            name="paint-config-canvas-color"
                                            className="bg-transparent rounded-none w-full h-10 p-0 border-0"
                                            type="color"
                                            value={gallery.config.image.background_color}
                                            onChange={(event) => {
                                                const galleryData = { ...gallery };
                                                galleryData.config.image.background_color = event.target.value;
                                                setGallery({ ...galleryData });
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="flex justify-center w-full mt-2 text-base">
                                    <label className="font-medium text-gray-400 w-full" htmlFor="paint-config-default-size-height">
                                        line count
                                        <input
                                            id="paint-config-default-size-height"
                                            type="number"
                                            className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                            min="1"
                                            value={gallery.config.image.line_count}
                                            onChange={(event) => {
                                                const galleryData = { ...gallery };
                                                galleryData.config.image.line_count = event.target.valueAsNumber;
                                                setGallery({ ...galleryData });
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full mt-5 flex  gap-5">
                    <button type="submit" className="bg-blue-600 text-white px-5 py-2 rounded-md">
                        submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Gallery;
