import axios, { AxiosError } from "axios";
import { Auth } from "../../../../contexts/auth";
import StatusList from "../../../../utils/status";
import { useContext, useState, useEffect } from "react";
import { Path } from "../../../../constants";
import _config from "../../../../config";
import { toast } from "sonner";
import { useParams, useNavigate } from "react-router-dom";
import NotFound from "../../../NotFound";
const PaintPage = (props: { paint: TPaint; onChange: (paint: TPaint) => void }) => {
    const { paint, onChange } = props;
    const { token } = useContext(Auth);
    const [users, setUesrs] = useState<User[]>([]);
    const [zipSize, SetZipSize] = useState({ width: 500, height: 500 });
    const { paintId } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responce = await axios.get(`${_config.api_server_uri}${Path.Admin.user.index}`, { headers: { Authorization: token } });
                if (responce.status !== 200) return toast.error(responce.data.message);
                setUesrs(responce.data.users);
            } catch (error) {
                if (error instanceof AxiosError && error.response?.data) toast.error(error.response.data.message);
                else if (error instanceof Error) toast.error(error.message);
                else toast.error("unkown error");
            }
        };
        fetchUser();
    }, [token]);
    const DeletePaint = async () => {
        const submit = async () => {
            try {
                const responce = await axios.delete(`${_config.api_server_uri}${Path.Admin.paint.id.replace(/:paintid/, paintId!)}`, { headers: { Authorization: token } });
                if (responce.status !== 200) return toast.error(responce.data.message);
                toast.success(responce.data.message);
                navigate("/admin");
            } catch (error) {
                if (error instanceof AxiosError && error.response?.data) {
                    toast.error(error.response.data.message);
                } else if (error instanceof Error) {
                    toast.error(error.message);
                }
            }
        };
        toast("do you want to delete this paint", {
            position: "bottom-left",
            action: {
                label: "yes",
                onClick: submit,
            },
            cancel: {
                label: "no",
            },
        });
    };
    if (!paintId) return <NotFound />;

    return (
        <>
            <div>
                <label htmlFor="paint-name" className="flex flex-col text-white">
                    <span className="cursor- ">name</span>
                    <input
                        type="text"
                        id="paint-name"
                        value={paint.name}
                        className="border my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        onChange={(event) => {
                            onChange({ ...paint, name: event.target.value });
                        }}
                    />
                </label>
            </div>
            <div>
                <label htmlFor="paint-status" className="flex flex-col text-white">
                    <span className="cursor-pointer">status</span>
                    <select
                        id="paint-status"
                        className="border  my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        value={paint.status}
                        onChange={(event) => {
                            if (StatusList.some((status) => event.target.value === status)) {
                                onChange({ ...paint, status: event.target.value as any });
                            }
                        }}
                    >
                        {StatusList.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div>
                <label htmlFor="paint-owner" className="flex flex-col text-white">
                    <span className="cursor-pointer">owner</span>
                    <select
                        id="paint-owner"
                        className="border my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        value={`${paint.user?.id}`}
                        onChange={(event) => {
                            const user = users.find((user) => user.id === event.target.value);
                            if (user) {
                                onChange({
                                    ...paint,
                                    user,
                                });
                            }
                        }}
                    >
                        {users.map((user) => (
                            <option key={user.id} value={user.id} className="py-5">
                                {user.email}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div className="w-full mt-5 flex gap-2">
                <div className="w-full text-base">
                    <span className="text-white">zip render size</span>
                    <div className="pl-5">
                        <div className="flex justify-center w-full mt-2 text-base">
                            <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-default-size-width">
                                width
                                <input
                                    id="paint-config-default-size-width"
                                    type="number"
                                    className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                    step="100"
                                    max="5000"
                                    min="100"
                                    value={zipSize.width}
                                    onChange={(event) => {
                                        SetZipSize((zipsize) => {
                                            zipSize.width = event.target.valueAsNumber;
                                            return { ...zipSize };
                                        });
                                    }}
                                />
                            </label>
                        </div>
                        <div className="flex justify-center w-full mt-2 text-base">
                            <label className="font-medium text-gray-400 w-full" htmlFor="paint-config-default-size-height">
                                height
                                <input
                                    id="paint-config-default-size-height"
                                    type="number"
                                    className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                    step="100"
                                    max="5000"
                                    min="100"
                                    value={zipSize.height}
                                    onChange={(event) => {
                                        SetZipSize((zipsize) => {
                                            zipSize.height = event.target.valueAsNumber;
                                            return { ...zipSize };
                                        });
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full mt-5 flex gap-2">
                <a href={`${_config.api_server_uri}${Path.Admin.Gallery.zip.replace(/:paintid/, paintId)}?w=${zipSize.width}&h=${zipSize.height}`}>
                    <button type="button" className="bg-blue-600 text-white px-5 py-2 rounded-md">
                        zip gallery
                    </button>
                </a>
                <button type="button" className="bg-red-600 text-white px-5 py-2 rounded-md" onClick={DeletePaint}>
                    delete this paint
                </button>
            </div>
        </>
    );
};
export default PaintPage;
