import { useNavigate } from "react-router-dom";

const Plans = (props: { paints: TPaint[] }) => {
    const { paints } = props;
    const dateFormateur = Intl.DateTimeFormat("en-US", { dateStyle: "medium", timeStyle: "short" });

    const navigate = useNavigate();

    return (
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            mural name
                        </th>
                        <th scope="col" className="py-3 px-6">
                            url
                        </th>
                        <th scope="col" className="py-3 px-6">
                            status
                        </th>
                        <th scope="col" className="py-3 px-6">
                            created
                        </th>
                        <th scope="col" className="py-3 px-6">
                            end date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {paints.map((paint) => (
                        <tr
                            key={paint.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                            onClick={(event) => {
                                navigate(`/admin/paint/${paint.id}`);
                            }}
                        >
                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {paint.name}
                            </th>
                            <td className="py-4 px-6">
                                <a href={`/paint/${paint.id}`} target="_blank" rel="noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                    {paint.name}
                                </a>
                            </td>
                            <td className="py-4 px-6">{paint.status}</td>
                            <td className="py-4 px-6">{dateFormateur.format(new Date(paint.create_at))}</td>
                            <td className="py-4 px-6">{paint.expiry_date ? dateFormateur.format(new Date(paint.expiry_date)) : "?"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
export default Plans;
