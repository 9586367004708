import { TPlanBase } from "../../../@types/plan";
import { config } from "../config";
export const plan: TPlanBase = {
    name: "default-plan",
    config: config,
    day_available: 30,
    description: "description",
    max_images_in_each_gallery: 100,
    options: ["plan", "plan1"],
    price: 100,
    questions: null,
    row: {
        number_options: [2, 3, 4],
        price: 1,
    },
    selecter_options: null,
};
