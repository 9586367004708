export namespace Path {
    export namespace socket {
        export const gallery = "/gallery";
        export const compose = "/compose";
        export const biggallery = "/big-gallery";
    }
    export namespace Auth {
        export const index = "/auth";
        export const signup = `${index}/signup`; // create user
        export const signin = `${index}/signin`; // login
        export const confirm = `${index}/confirm/:token`;
        export const changePassword = `${index}/change-password`;
        export namespace forgotPassword {
            export const index = `${Auth.index}/forgot-password`;
            export const sendEmail = `${index}/send-email`;
            export const verifyToken = `${index}/:token`;
        }
    }
    export namespace Dashboard {
        export const index = "/dashboard";
        export namespace stripe {
            export const index = `${Dashboard.index}/stripe`;
            export const public_key = `${stripe.index}/pk`;
        }
        export namespace plan {
            export const index = `${Dashboard.index}/plan`;
            export const get = `${index}/:planid`;
        }
        export namespace paint {
            export const index = `${Dashboard.index}/paint`;
            export const get = `${paint.index}/:paintid`;
            export const create = `${index}/create`;
            export const client_secret = `${index}/clientSecret/:paintid`;
            // export const compose = `${index}/compose/:paintid`;
        }
    }
    export namespace Gallery {
        export const index = "/gallery";
        export const render = `${index}/render/:imageid`;
        export const post = `${index}/post/:paintid`;
        export const get = `${index}/:paintid`;

        export namespace compose {
            export const index = `${Gallery.index}/compose`;
            export const get = `${index}/:paintid`;
            export const post = `${index}/post/:paintid`;
            export const get_data = `${index}/data/:paintid`;
        }
    }
    export namespace Admin {
        export const index = "/admin";
        export namespace plan {
            export const index = `${Admin.index}/plan`;
            export const create = `${index}/create`;
            export const id = `${index}/:planid`;
        }
        export namespace paint {
            export const index = `${Admin.index}/paint`;
            export const create = `${index}/create`;
            export const id = `${index}/:paintid`;
        }
        export namespace upload {
            export const index = `${Admin.index}/svg`;
            export const id = `${index}/:svgid`;
        }
        export namespace user {
            export const index = `${Admin.index}/user`;
        }
        export namespace Gallery {
            export const index = `${Admin.index}/gallery`;
            export const zip = `${index}/zip/:paintid`;

            export namespace image {
                export const index = `${Gallery.index}/image`;
                export const change_status = `${index}/change-status/:imageid`;
            }
        }
        export namespace Big_gallery {
            export const index = `${Admin.index}/big-gallery`;
            export const id = `${index}/:biggalleryid`;
        }
    }
    export namespace Webhook {
        export const index = `/webhook`;
    }
    export namespace Svg {
        export const index = "/svg";
        export const id = `${index}/:svgid`;
    }
    export namespace BigGallery {
        export const index = `/big-gallery`;
        export const get = `${index}/:biggalleryid`;
    }
}
