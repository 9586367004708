import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "../NotFound";
import { Path } from "../../constants";
import _config from "../../config";

import { TStorage } from "../../@types/gallery";
import { toast } from "sonner";
import Loader from "../../components/loader";
import { manager } from "../../socket";

interface Paint {
    name: string;
    config: Config;
    gallery: { storages: TStorage[] };
}
interface EventImage {
    imageid: string;
    labelid: string;
    username?: string;
    status: "active" | "deactive";
    date: string;
}

const Gallery = () => {
    const [paint, setPaint] = useState<Paint | null>(null);
    const [isloading, setisloading] = useState<boolean>(true);
    const { paintId } = useParams();

    useEffect(() => {
        const load = async () => {
            if (!paintId) throw new Error("error to find paintid");
            const response = await axios.get(`${_config.api_server_uri}${Path.Gallery.get.replace(/:paintid/, paintId)}`);
            if (response.status === 200) {
                setPaint(response.data.paint);
            } else {
                throw new Error("error to load paint");
            }
        };

        toast.promise(load(), {
            loading: "loading...",
            position: "top-center",
            error: () => {
                setisloading(false);
                return <>error to load paint</>;
            },
            success: () => {
                setisloading(false);
                return <>paint load successfully</>;
            },
        });
    }, [paintId]);

    useEffect(() => {
        const socket = manager.socket(Path.socket.gallery, {
            auth: {
                paint: paintId,
            },
        });

        socket.on("disconnect", () => {
            toast.error("socket event disconnect", { position: "top-center" });
        });

        socket.on("reconnect", () => {
            toast.success("socket event reconnect", { position: "top-center" });
        });

        socket.connect();
        socket.on("change-status", (event: EventImage) => {
            if (!paint) return;

            const gallery = { ...paint.gallery };
            const labelindex = paint.gallery.storages.findIndex((storage) => storage.id === event.labelid);
            const storage = paint.gallery.storages[labelindex];

            if (storage) {
                const image = storage.images.find((image) => image.id === event.imageid);
                const imageIndex = storage.images.findIndex((image) => image.id === event.imageid);
                if (image) {
                    if (event.status === "deactive") {
                        storage.images.splice(imageIndex, 1);
                    }
                } else {
                    storage.images.push({
                        date: event.date,
                        id: event.imageid,
                        status: event.status,
                        username: event.username,
                    });
                }
            }
            for (let i = 0; i < paint.gallery.storages.length; i++) {
                const storage = paint.gallery.storages[i];
                storage.images.sort((a, b) => {
                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                });
            }
            setPaint((paint) => {
                if (!paint) return null;
                paint.gallery = gallery;
                return { ...paint };
            });
        });

        return () => {
            socket.off();
            socket.disconnect();
        };
    }, [paint, paintId]);

    if (isloading) return <Loader />;
    return (
        <div className="w-screen overflow-x-hidden">
            {paint ? (
                <div className="dark:bg-gray-800 dark:text-white relative w-screen h-screen">
                    <div className="w-screen h-16 flex justify-center items-center">
                        <h2 className="text-3xl">{paint.name}</h2>
                    </div>

                    {paint.gallery.storages ? (
                        <div className="flex items-center justify-center h-auto dark:bg-gray-800 flex-col">
                            {paint.gallery.storages.map((storage, index) => {
                                return (
                                    <div className="flex justify-center items-center flex-col my-10" key={index}>
                                        {storage.images.length > 0 ? (
                                            <>
                                                <h2 className="text-2xl w-full h-12 text-center">{storage.label === "default" ? null : storage.label}</h2>
                                                <div className="grid grid-cols-4 items-center gap-1" style={{ gridTemplateColumns: `repeat(${paint.config.gallery.image_line}, minmax(0, 1fr)` }}>
                                                    {storage.images.map((image, index) => {
                                                        return (
                                                            <div className="w-full flex items-center justify-center max-w-md" key={index}>
                                                                <img src={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, image.id)}?width=500&height=500`} alt="" />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="w-full h-1/2 flex justify-center items-center opacity-60">gallery empty</div>
                    )}
                </div>
            ) : (
                <NotFound />
            )}
        </div>
    );
};

export default Gallery;
// export const loader: LoaderFunction = async ({ params }) => {
//     // try {
//     //     const res = await axios.post(`${config.api_server_uri}/paint/gallery/${params.paintId}`);
//     //     if (res.status === 200) {
//     //         return res.data;
//     //     } else {
//     //         return redirect('/gallery')
//     //     }
//     // } catch (err) {
//     //     return redirect('/gallery')
//     // }
// };
