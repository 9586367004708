const labelStatus = ["active", "deactive"];
const GalleryPage = (props: { paint: TPaintBase; onChange: (paint: TPaintBase) => void }) => {
    const { paint, onChange } = props;

    return (
        <>
            <div className="mt-2">
                {paint.compose ? (
                    <div className="my-2 ">
                        <h3 className="text-sm font-medium text-gray-400 mb-3 underline">compose</h3>
                        <div className="flex justify-center items-center flex-col gap-2 w-full pl-3 text-gray-400">
                            <div className="w-full text-blue-700 underline">
                                {/* <a href={`/gallery/${paint.id}/compose`} target="_blank" rel="noreferrer">
                                    compose gallery link
                                </a> */}
                            </div>
                            {/* <div className="w-full text-base">
                                <span>image line</span>
                                <div className="pl-5">
                                    <div className="flex justify-center w-full mt-3 text-base">
                                        <div className="w-full">
                                            <label className="relative inline-flex items-center mb-2 cursor-pointer" htmlFor="paint-config-compose-gallery-image-line-use">
                                                <input
                                                    type="checkbox"
                                                    className="sr-only peer"
                                                    id="paint-config-compose-gallery-image-line-use"
                                                    value=""
                                                    checked={paint.config.gallery.image_line.state}
                                                    onChange={(event) => {
                                                        const config = { ...paint.config };
                                                        config.gallery.image_line.state = event.target.checked;
                                                        // canvas.default_size.width = event.target.valueAsNumber;
                                                        onChange({ ...paint, config });
                                                    }}
                                                />
                                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span className="ml-3 text-sm font-medium ">use</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-center w-full text-base">
                                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-compose-gallery-image-line-count">
                                            count
                                            <input
                                                id="paint-config-compose-gallery-image-line-count"
                                                type="number"
                                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                min="1"
                                                // value={paint.compose?.gallery}
                                                onChange={(event) => {
                                                    const config = { ...paint.config };
                                                    config.gallery.image_line.count = event.target.valueAsNumber;
                                                    onChange({ ...paint, config });
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="w-full text-base mt-2">
                                <span>gallery</span>
                                <div className="pl-5">
                                    <div className="flex justify-center w-full text-base mt-2">
                                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-compose-gallery-image-line-count">
                                            max image
                                            <input
                                                id="paint-config-compose-gallery-image-line-count"
                                                type="number"
                                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                min="1"
                                                value={paint.compose.max_images}
                                                onChange={(event) => {
                                                    if (!paint.compose) return;
                                                    const compose = { ...paint.compose };
                                                    compose.max_images = event.target.valueAsNumber;
                                                    onChange({ ...paint, compose });
                                                }}
                                            />
                                        </label>
                                    </div>
                                    {/* <div className="w-full text-base mt-5">
                                        <ComposeImage paint={paint} onChange={onChange} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="pl-5 w-full flex justify-start items-center text-white mt-10"> compose is null</div>
                    </>
                )}
                <h3 className="text-sm font-medium text-gray-400 mb-3 underline">gallery</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3 text-gray-400">
                    <div className="w-full text-blue-700 underline">
                        {/* <a href={`/gallery/${paint.id}`} target="_blank" rel="noreferrer">
                            gallery link
                        </a> */}
                    </div>
                    <div className="w-full mt-2">
                        <label className="relative inline-flex items-center mb-2 cursor-pointer" htmlFor="paint-config-gallery-live_update">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                id="paint-config-gallery-live_update"
                                value=""
                                checked={paint.config.gallery.live_update}
                                onChange={(event) => {
                                    const config = { ...paint.config };
                                    config.gallery.live_update = event.target.checked;
                                    // canvas.default_size.width = event.target.valueAsNumber;
                                    onChange({ ...paint, config });
                                }}
                            />
                            <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium ">live update</span>
                        </label>
                    </div>
                    <div className="w-full text-base">
                        <span>image line</span>
                        <div className="pl-5">
                            <div className="flex justify-center w-full mt-3 text-base">
                                <div className="w-full">
                                    <label className="relative inline-flex items-center mb-2 cursor-pointer" htmlFor="paint-config-gallery-image-line-use">
                                        <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            id="paint-config-gallery-image-line-use"
                                            value=""
                                            checked={paint.config.gallery.image_line.state}
                                            onChange={(event) => {
                                                const config = { ...paint.config };
                                                config.gallery.image_line.state = event.target.checked;
                                                // canvas.default_size.width = event.target.valueAsNumber;
                                                onChange({ ...paint, config });
                                            }}
                                        />
                                        <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span className="ml-3 text-sm font-medium ">use</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex justify-center w-full text-base">
                                <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-gallery-image-line-count">
                                    count
                                    <input
                                        id="paint-config-gallery-image-line-count"
                                        type="number"
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                        min="1"
                                        value={paint.config.gallery.image_line.count}
                                        onChange={(event) => {
                                            const config = { ...paint.config };
                                            config.gallery.image_line.count = event.target.valueAsNumber;
                                            onChange({ ...paint, config });
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-base">
                        <span>labels</span>
                        <div className="pl-5">
                            <label className="relative inline-flex items-center cursor-pointer my-3" htmlFor="paint-config-gallery-use-multi-label">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    id="paint-config-gallery-use-multi-label"
                                    value=""
                                    checked={paint.gallery.isonly}
                                    onChange={(event) => {
                                        const gallery = { ...paint.gallery };
                                        gallery.isonly = event.target.checked;
                                        onChange({ ...paint, gallery });
                                    }}
                                />
                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium ">use multi label gallery</span>
                            </label>
                            {paint.gallery.isonly ? (
                                <div>
                                    <div className="mb-2">
                                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-gallery-label-default-max-image">
                                            max image
                                            <input
                                                id="paint-config-gallery-label-default-max-image"
                                                type="number"
                                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                min="1"
                                                value={paint.gallery.storages[0].max_images}
                                                onChange={(event) => {
                                                    const gallery = { ...paint.gallery };
                                                    gallery.storages[0].max_images = event.target.valueAsNumber;

                                                    onChange({ ...paint, gallery });
                                                }}
                                            />
                                        </label>
                                    </div>
                                    {/* <GalleryImage onChange={onChange} paint={paint} labelIndex={0} /> */}
                                </div>
                            ) : (
                                paint.gallery.storages.map((storage, index) => {
                                    return (
                                        <div className="bg-gray-700 px-5 py-5 rounded-xl my-2">
                                            <div className="mb-2">
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-label`}>
                                                    label
                                                    <input
                                                        id={`paint-config-gallery-label-${index}-label`}
                                                        type="text"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        min="1"
                                                        value={paint.gallery.storages[index].label || ""}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].label = event.target.value;

                                                            // config.gallery.image_line.count = event.target.valueAsNumber;
                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    />
                                                </label>
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-max-image`}>
                                                    max image
                                                    <input
                                                        id={`paint-config-gallery-label-${index}-max-image`}
                                                        type="number"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        min="1"
                                                        value={paint.gallery.storages[index].max_images}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].max_images = event.target.valueAsNumber;

                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    />
                                                </label>
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-question`}>
                                                    question
                                                    <input
                                                        id={`paint-config-gallery-label-${index}-question`}
                                                        type="text"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        value={paint.gallery.storages[index].question}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].question = event.target.value;

                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    />
                                                </label>
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-status`}>
                                                    status
                                                    <select
                                                        id={`paint-config-gallery-label-${index}-status`}
                                                        className="border my-3 text-sm rounded-lg block w-full p-2.5 text-white bg-gray-800 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                                        value={storage.status}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].status = event.target.value as any;
                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    >
                                                        {labelStatus.map((label, index) => (
                                                            <option value={label} key={index}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* <input
                                                        id={`paint-config-gallery-label-${index}-question`}
                                                        type="text"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        value={paint.gallery.storages[index].question}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].question = event.target.value;

                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    /> */}
                                                </label>
                                            </div>
                                            {/* <GalleryImage onChange={onChange} paint={paint} labelIndex={index} /> */}
                                        </div>
                                    );
                                })
                            )}
                            {!paint.gallery.isonly && (
                                <>
                                    <div className="w-full mt-3 flex justify-end" title="new label gallery">
                                        <div
                                            className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                                            onClick={() => {
                                                const gallery = { ...paint.gallery };
                                                gallery.storages.push({
                                                    images: [],
                                                    label: "",
                                                    max_images: 10,
                                                    status: "active",
                                                    id: null,
                                                });
                                                onChange({ ...paint, gallery });
                                            }}
                                        >
                                            <svg
                                                className="w-10 h-10 text-white"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GalleryPage;
