import { useContext, useState } from "react";
import { createPortal } from "react-dom";
import { toast } from "sonner";
import _config from "../../../../config";
import { Path } from "../../../../constants";
import axios, { AxiosError } from "axios";
import { Auth } from "../../../../contexts/auth";
import { TStorage } from "../../../../@types/gallery";

const dateFormateur = Intl.DateTimeFormat("en-US", { dateStyle: "medium", timeStyle: "short" });
const labelStatus = ["active", "deactive"];

const GalleryImage = (props: { paint: TPaint; onChange: (paint: TPaint) => void; labelIndex: number }) => {
    const { paint, labelIndex, onChange } = props;
    const [showList, setShowList] = useState<boolean>(false);
    const [currentImageSelect, setCurrentImageSelect] = useState(0);
    const { token } = useContext(Auth);
    const change_status = async (status: TStorage["images"][number]["status"], imageid: string) => {
        const galleryid = paint.gallery.id!;
        const labelid = paint.gallery.storages[labelIndex].id!;
        await axios.post(
            `${_config.api_server_uri}${Path.Admin.Gallery.image.change_status.replace(/:imageid/, imageid)}`,
            { status, galleryid, labelid, type: "gallery" },
            { headers: { Authorization: token } }
        );
    };
    const deactiveImage = (imageid: string) => {
        toast.promise(change_status("deactive", imageid), {
            position: "top-center",
            error(error) {
                if (error instanceof AxiosError) {
                    return <>{error.response?.data.message} </>;
                } else {
                    return <>error to deactive image </>;
                }
            },
            success() {
                const gallery = { ...paint.gallery };
                const storage = gallery.storages[labelIndex];
                const image = storage.images.find((image) => image.id === imageid);
                if (image) {
                    image.status = "deactive";
                    onChange({
                        ...paint,
                        gallery,
                    });
                }
                return <> image as been deactived successfuly</>;
            },
            loading: "deactiving image",
        });
    };
    const activeImage = (imageid: string) => {
        toast.promise(change_status("active", imageid), {
            position: "top-center",
            error(error) {
                return <>error to active image </>;
            },
            success() {
                const gallery = { ...paint.gallery };
                const storage = gallery.storages[labelIndex];
                const image = storage.images.find((image) => image.id === imageid);
                if (image) {
                    image.status = "active";
                    onChange({
                        ...paint,
                        gallery,
                    });
                }

                return <> image as been actived successfuly</>;
            },
            loading: "activing image",
        });
        // toast("a you sure,you real went to active this image", {
        //     style: {
        //         color: "green",
        //     },
        //     duration: 5000,
        //     important: true,
        //     position: "top-center",
        //     cancel: {
        //         label: "cancel",
        //     },
        //     action: {
        //         label: "active",
        //         onClick: () => {
        //             toast.promise(change_status("active", imageid), {
        //                 position: "top-center",
        //                 error(error) {
        //                     return <>error to active image </>;
        //                 },
        //                 success() {
        //                     const gallery = { ...paint.gallery };
        //                     const storage = gallery.storages[labelIndex];
        //                     const image = storage.images.find((image) => image.id === imageid);
        //                     if (image) {
        //                         image.status = "active";
        //                         onChange({
        //                             ...paint,
        //                             gallery,
        //                         });
        //                     }

        //                     return <> image as been actived successfuly</>;
        //                 },
        //                 loading: "activing image",
        //             });
        //         },
        //     },
        // });
    };

    if (!showList)
        return (
            <div className="w-full">
                <button
                    onClick={() => {
                        setShowList((showList) => !showList);
                    }}
                    type="button"
                    className="flex text-white  focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                >
                    config images
                </button>
            </div>
        );

    return createPortal(
        <div className={`w-full h-auto absolute top-0 left-0 bg-gray-800 flex justify-center items-center pt-20 z-40`}>
            <div className="absolute top-[50px] right-[50px] w-[100px] h-[100px] ">
                <div
                    className="flex justify-center items-center h-[80px] w-[80px]  p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer"
                    onClick={() => setShowList(false)}
                >
                    <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>

            <div className="flex w-full px-5 lg:px-28">
                {paint.gallery.storages[labelIndex].images.length === 0 ? (
                    <div className="w-full h-screen flex justify-center items-center">
                        <h2 className="text-white text-3xl">gallery is emtry</h2>
                    </div>
                ) : (
                    <>
                        <div className="w-[30%] h-screen flex justify-start top-10 items-center text-white px-2 md:px-10 sticky flex-col min-w-[200px]">
                            {paint.gallery.storages[labelIndex].images[currentImageSelect] && (
                                <>
                                    <img
                                        src={`${_config.api_server_uri}${Path.Gallery.render.replace(
                                            /:imageid/,
                                            paint.gallery.storages[labelIndex].images[currentImageSelect].id
                                        )}?width=500&height=500`}
                                        alt=""
                                    />
                                    <span className="py-3">{paint.gallery.storages[labelIndex].images[currentImageSelect].date}</span>
                                </>
                            )}
                        </div>
                        <div className="w-[70%] pt-8">
                            <div>
                                {paint.gallery.storages[labelIndex].images.map((image, index) => {
                                    return (
                                        <div className="flex justify-center items-center w-full my-2 text-white" key={index}>
                                            <div
                                                className={`w-full cursor-pointer h-14 bg-gray-700 ${
                                                    index === currentImageSelect ? "bg-gray-600" : "hover:bg-gray-600"
                                                } rounded-lg grid grid-cols-4  place-items-center`}
                                                onMouseEnter={() => {
                                                    setCurrentImageSelect(index);
                                                }}
                                            >
                                                <div>{image.status}</div>
                                                <div>{image.username || "no name"}</div>
                                                <div>{dateFormateur.format(new Date(image.date))}</div>
                                                <div className="flex justify-end items-center w-full pr-2 gap-3">
                                                    {image.status === "active" ? (
                                                        <div
                                                            className={`w-14 h-10 flex justify-center items-center cursor-pointer rounded-lg bg-red-600 hover:bg-red-700`}
                                                            onClick={() => {
                                                                deactiveImage(image.id);
                                                            }}
                                                        >
                                                            <div className="w-full  flex justify-center items-center">
                                                                <svg
                                                                    className={`w-5 h-5 text-gray-800 dark:text-white transition-transform rotate-45 `}
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 18 18"
                                                                >
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`w-14 h-10 flex justify-center items-center cursor-pointer rounded-lg bg-blue-600 hover:bg-blue-700`}
                                                            onClick={() => {
                                                                activeImage(image.id);
                                                            }}
                                                        >
                                                            <div className="w-full  flex justify-center items-center">
                                                                <svg
                                                                    className="w-5 h-5 text-gray-800 dark:text-white"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 16 12"
                                                                >
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>,
        document.body
    );
};
const ComposeImage = (props: { paint: TPaint; onChange: (paint: TPaint) => void }) => {
    const { paint, onChange } = props;
    const [showList, setShowList] = useState<boolean>(false);
    const [currentImageSelect, setCurrentImageSelect] = useState(0);
    const { token } = useContext(Auth);
    const change_status = async (status: TStorage["images"][number]["status"], imageid: string) => {
        if (!paint.compose) return;
        const galleryid = paint.compose.id!;
        await axios.post(
            `${_config.api_server_uri}${Path.Admin.Gallery.image.change_status.replace(/:imageid/, imageid)}`,
            { status, galleryid, type: "compose" },
            { headers: { Authorization: token } }
        );
    };
    const deactiveImage = (imageid: string) => {
        toast.promise(change_status("deactive", imageid), {
            position: "top-center",
            error(error) {
                if (error instanceof AxiosError) {
                    return <>{error.response?.data.message} </>;
                } else {
                    return <>error to deactive image </>;
                }
            },
            success() {
                if (!paint.compose) return;
                const compose = { ...paint.compose };
                const image = compose.gallery.images.find((image) => image.id === imageid);
                if (image) {
                    image.status = "deactive";
                    onChange({
                        ...paint,
                        compose,
                    });
                }
                return <> image as been deactived successfuly</>;
            },
            loading: "deactiving image",
        });
    };
    const activeImage = (imageid: string) => {
        toast.promise(change_status("active", imageid), {
            position: "top-center",
            error(error) {
                return <>error to active image </>;
            },
            success() {
                if (!paint.compose) return;
                const compose = { ...paint.compose };
                const image = compose.gallery.images.find((image) => image.id === imageid);
                if (image) {
                    image.status = "active";
                    onChange({
                        ...paint,
                        compose,
                    });
                }

                return <> image as been actived successfuly</>;
            },
            loading: "activing image",
        });
    };

    if (!showList)
        return (
            <div className="w-full">
                <button
                    onClick={() => {
                        setShowList((showList) => !showList);
                    }}
                    type="button"
                    className="flex text-white  focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
                >
                    config images
                </button>
            </div>
        );

    return createPortal(
        <div className={`w-full h-auto absolute top-0 left-0 bg-gray-800 flex justify-center items-center pt-20 z-40`}>
            <div className="absolute top-[50px] right-[50px] w-[100px] h-[100px] ">
                <div
                    className="flex justify-center items-center h-[80px] w-[80px]  p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer"
                    onClick={() => setShowList(false)}
                >
                    <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </div>
            </div>

            <div className="flex w-full px-5 lg:px-28">
                {paint.compose ? (
                    <>
                        {paint.compose.gallery.images.length === 0 ? (
                            <div className="w-full h-screen flex justify-center items-center">
                                <h2 className="text-white text-3xl">gallery is emtry</h2>
                            </div>
                        ) : (
                            <>
                                <div className="w-[30%] h-screen flex justify-start top-10 items-center text-white px-2 md:px-10 sticky flex-col min-w-[200px]">
                                    {paint.compose.gallery.images[currentImageSelect] && (
                                        <>
                                            <img
                                                src={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, paint.compose?.gallery.images[currentImageSelect].id)}?width=500&height=500`}
                                                alt=""
                                            />
                                            <span className="py-3">{paint.compose?.gallery.images[currentImageSelect].date}</span>
                                        </>
                                    )}
                                </div>
                                <div className="w-[70%] pt-8">
                                    <div>
                                        {paint.compose.gallery.images.map((image, index) => {
                                            return (
                                                <div className="flex justify-center items-center w-full my-2 text-white" key={index}>
                                                    <div
                                                        className={`w-full cursor-pointer h-14 bg-gray-700 ${
                                                            index === currentImageSelect ? "bg-gray-600" : "hover:bg-gray-600"
                                                        } rounded-lg grid grid-cols-4  place-items-center`}
                                                        onMouseEnter={() => {
                                                            setCurrentImageSelect(index);
                                                        }}
                                                    >
                                                        <div>{image.status}</div>
                                                        <div>{image.username || "no name"}</div>
                                                        <div>{dateFormateur.format(new Date(image.date))}</div>
                                                        <div className="flex justify-end items-center w-full pr-2 gap-3">
                                                            {image.status === "active" ? (
                                                                <div
                                                                    className={`w-14 h-10 flex justify-center items-center cursor-pointer rounded-lg bg-red-600 hover:bg-red-700`}
                                                                    onClick={() => {
                                                                        deactiveImage(image.id);
                                                                    }}
                                                                >
                                                                    <div className="w-full  flex justify-center items-center">
                                                                        <svg
                                                                            className={`w-5 h-5 text-gray-800 dark:text-white transition-transform rotate-45 `}
                                                                            aria-hidden="true"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 18 18"
                                                                        >
                                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={`w-14 h-10 flex justify-center items-center cursor-pointer rounded-lg bg-blue-600 hover:bg-blue-700`}
                                                                    onClick={() => {
                                                                        activeImage(image.id);
                                                                    }}
                                                                >
                                                                    <div className="w-full  flex justify-center items-center">
                                                                        <svg
                                                                            className="w-5 h-5 text-gray-800 dark:text-white"
                                                                            aria-hidden="true"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 16 12"
                                                                        >
                                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="w-full h-screen text-white flex justify-center items-center">compose is "null"</div>
                )}
            </div>
        </div>,
        document.body
    );
};

const GalleryPage = (props: { paint: TPaint; onChange: (paint: TPaint) => void }) => {
    const { paint, onChange } = props;

    return (
        <>
            <div className="mt-2">
                {paint.compose ? (
                    <div className="my-2 ">
                        <h3 className="text-sm font-medium text-gray-400 mb-3 underline">compose</h3>
                        <div className="flex justify-center items-center flex-col gap-2 w-full pl-3 text-gray-400">
                            <div className="w-full text-blue-700 underline">
                                <a href={`/gallery/${paint.id}/compose`} target="_blank" rel="noreferrer">
                                    compose gallery link
                                </a>
                            </div>
                            {/* <div className="w-full text-base">
                                <span>image line</span>
                                <div className="pl-5">
                                    <div className="flex justify-center w-full mt-3 text-base">
                                        <div className="w-full">
                                            <label className="relative inline-flex items-center mb-2 cursor-pointer" htmlFor="paint-config-compose-gallery-image-line-use">
                                                <input
                                                    type="checkbox"
                                                    className="sr-only peer"
                                                    id="paint-config-compose-gallery-image-line-use"
                                                    value=""
                                                    checked={paint.config.gallery.image_line.state}
                                                    onChange={(event) => {
                                                        const config = { ...paint.config };
                                                        config.gallery.image_line.state = event.target.checked;
                                                        // canvas.default_size.width = event.target.valueAsNumber;
                                                        onChange({ ...paint, config });
                                                    }}
                                                />
                                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                                <span className="ml-3 text-sm font-medium ">use</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-center w-full text-base">
                                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-compose-gallery-image-line-count">
                                            count
                                            <input
                                                id="paint-config-compose-gallery-image-line-count"
                                                type="number"
                                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                min="1"
                                                // value={paint.compose?.gallery}
                                                onChange={(event) => {
                                                    const config = { ...paint.config };
                                                    config.gallery.image_line.count = event.target.valueAsNumber;
                                                    onChange({ ...paint, config });
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="w-full text-base mt-2">
                                <span>gallery</span>
                                <div className="pl-5">
                                    <div className="flex justify-center w-full text-base mt-2">
                                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-compose-gallery-image-line-count">
                                            max image
                                            <input
                                                id="paint-config-compose-gallery-image-line-count"
                                                type="number"
                                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                min="1"
                                                value={paint.compose.gallery.max_images}
                                                onChange={(event) => {
                                                    if (!paint.compose) return;
                                                    const compose = { ...paint.compose };
                                                    compose.gallery.max_images = event.target.valueAsNumber;
                                                    onChange({ ...paint, compose });
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <div className="w-full text-base mt-5">
                                        <ComposeImage paint={paint} onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="pl-5 w-full flex justify-start items-center text-white mt-10"> compose is null</div>
                    </>
                )}
                <h3 className="text-sm font-medium text-gray-400 mb-3 underline">gallery</h3>
                <div className="flex justify-center items-center flex-col gap-2 w-full pl-3 text-gray-400">
                    <div className="w-full text-blue-700 underline">
                        <a href={`/gallery/${paint.id}`} target="_blank" rel="noreferrer">
                            gallery link
                        </a>
                    </div>
                    <div className="w-full mt-2">
                        <label className="relative inline-flex items-center mb-2 cursor-pointer" htmlFor="paint-config-gallery-live_update">
                            <input
                                type="checkbox"
                                className="sr-only peer"
                                id="paint-config-gallery-live_update"
                                value=""
                                checked={paint.config.gallery.live_update}
                                onChange={(event) => {
                                    const config = { ...paint.config };
                                    config.gallery.live_update = event.target.checked;
                                    // canvas.default_size.width = event.target.valueAsNumber;
                                    onChange({ ...paint, config });
                                }}
                            />
                            <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium ">live update</span>
                        </label>
                    </div>
                    <div className="w-full text-base">
                        <span>image line</span>
                        <div className="pl-5">
                            <div className="flex justify-center w-full mt-3 text-base">
                                <div className="w-full">
                                    <label className="relative inline-flex items-center mb-2 cursor-pointer" htmlFor="paint-config-gallery-image-line-use">
                                        <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            id="paint-config-gallery-image-line-use"
                                            value=""
                                            checked={paint.config.gallery.image_line.state}
                                            onChange={(event) => {
                                                const config = { ...paint.config };
                                                config.gallery.image_line.state = event.target.checked;
                                                // canvas.default_size.width = event.target.valueAsNumber;
                                                onChange({ ...paint, config });
                                            }}
                                        />
                                        <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span className="ml-3 text-sm font-medium ">use</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex justify-center w-full text-base">
                                <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-gallery-image-line-count">
                                    count
                                    <input
                                        id="paint-config-gallery-image-line-count"
                                        type="number"
                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                        min="1"
                                        value={paint.config.gallery.image_line.count}
                                        onChange={(event) => {
                                            const config = { ...paint.config };
                                            config.gallery.image_line.count = event.target.valueAsNumber;
                                            onChange({ ...paint, config });
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-base">
                        <span>labels</span>
                        <div className="pl-5">
                            <label className="relative inline-flex items-center cursor-pointer my-3" htmlFor="paint-config-gallery-use-multi-label">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    id="paint-config-gallery-use-multi-label"
                                    value=""
                                    checked={paint.gallery.isonly}
                                    onChange={(event) => {
                                        const gallery = { ...paint.gallery };
                                        gallery.isonly = event.target.checked;
                                        onChange({ ...paint, gallery });
                                    }}
                                />
                                <div className="w-11 h-6 peer-focus:outline-none peer-focus:ring-4 p rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-3 text-sm font-medium ">use multi label gallery</span>
                            </label>
                            {paint.gallery.isonly ? (
                                <div>
                                    <div className="mb-2">
                                        <label className="font-medium text-gray-400 w-full " htmlFor="paint-config-gallery-label-default-max-image">
                                            max image
                                            <input
                                                id="paint-config-gallery-label-default-max-image"
                                                type="number"
                                                className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                min="1"
                                                value={paint.gallery.storages[0].max_images}
                                                onChange={(event) => {
                                                    const gallery = { ...paint.gallery };
                                                    gallery.storages[0].max_images = event.target.valueAsNumber;

                                                    onChange({ ...paint, gallery });
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <GalleryImage onChange={onChange} paint={paint} labelIndex={0} />
                                </div>
                            ) : (
                                paint.gallery.storages.map((storage, index) => {
                                    return (
                                        <div className="bg-gray-700 px-5 py-5 rounded-xl my-2">
                                            <div className="mb-2">
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-label`}>
                                                    label
                                                    <input
                                                        id={`paint-config-gallery-label-${index}-label`}
                                                        type="text"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        min="1"
                                                        value={paint.gallery.storages[index].label || ""}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].label = event.target.value;

                                                            // config.gallery.image_line.count = event.target.valueAsNumber;
                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    />
                                                </label>
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-max-image`}>
                                                    max image
                                                    <input
                                                        id={`paint-config-gallery-label-${index}-max-image`}
                                                        type="number"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        min="1"
                                                        value={paint.gallery.storages[index].max_images}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].max_images = event.target.valueAsNumber;

                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    />
                                                </label>
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-question`}>
                                                    question
                                                    <input
                                                        id={`paint-config-gallery-label-${index}-question`}
                                                        type="text"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        value={paint.gallery.storages[index].question}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].question = event.target.value;

                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    />
                                                </label>
                                                <label className="font-medium text-gray-400 w-full mb-2 block" htmlFor={`paint-config-gallery-label-${index}-status`}>
                                                    status
                                                    <select
                                                        id={`paint-config-gallery-label-${index}-status`}
                                                        className="border my-3 text-sm rounded-lg block w-full p-2.5 text-white bg-gray-800 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                                        value={storage.status}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].status = event.target.value as any;
                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    >
                                                        {labelStatus.map((label, index) => (
                                                            <option value={label} key={index}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {/* <input
                                                        id={`paint-config-gallery-label-${index}-question`}
                                                        type="text"
                                                        className="font-bold text-white bg-gray-800 rounded-lg w-full py-2 px-2 mt-2"
                                                        value={paint.gallery.storages[index].question}
                                                        onChange={(event) => {
                                                            const gallery = { ...paint.gallery };
                                                            gallery.storages[index].question = event.target.value;

                                                            onChange({ ...paint, gallery });
                                                        }}
                                                    /> */}
                                                </label>
                                            </div>
                                            <GalleryImage onChange={onChange} paint={paint} labelIndex={index} />
                                        </div>
                                    );
                                })
                            )}
                            {!paint.gallery.isonly && (
                                <>
                                    <div className="w-full mt-3 flex justify-end" title="new label gallery">
                                        <div
                                            className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                                            onClick={() => {
                                                const gallery = { ...paint.gallery };
                                                gallery.storages.push({
                                                    images: [],
                                                    label: "",
                                                    max_images: 10,
                                                    status: "active",
                                                    id: null,
                                                });
                                                onChange({ ...paint, gallery });
                                            }}
                                        >
                                            <svg
                                                className="w-10 h-10 text-white"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GalleryPage;
