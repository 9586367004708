import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import Login from "./login";
import ForgotPassword from "./login/forgot-password";
import Register, { loader as RegisterLoader } from "./register";

import Dashboard from "./dashboard";
import PaintDashboard from "./dashboard/paint";

import Paint, { loader as PaintLoader } from "./paint";
import Home from "./home";
import Gallery from "./gallery";

import Plans from "./plans";
import PayPaint from "./dashboard/paint/pay";
import BuyPlan from "./plans/buy";

import Admin from "./admin";
import AdminPaint from "./admin/paint/";
import AdminCreatePaint from "./admin/paint/create";

import AdminPlan from "./admin/plan/";
import AdminCratePlan from "./admin/plan/create";

import AdminGallery from "./admin/gallery";
import AdminCreateGallery from "./admin/gallery/create";

import Auth from "./auth";
import AuthConfirm, { loader as AuthConfirmLoader } from "./auth/confirm";
import RestPassword, { loader as RestPasswordLoader } from "./auth/rest-password";

import Compose from "./compose";

import About from "./about";

import NotFound from "./NotFound";
import ComposeGallery from "./compose-gallery";
import BigGallery from "./big-gallery";

const Routes = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/">
                <Route index element={<Home />} />
                <Route path="login">
                    <Route index element={<Login />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>
                <Route path="register" element={<Register />} loader={RegisterLoader} />
                <Route path="admin">
                    <Route index element={<Admin />} />
                    <Route path="paint">
                        <Route path="create" element={<AdminCreatePaint />} />
                        <Route path=":paintId" element={<AdminPaint />} />
                    </Route>
                    <Route path="plan">
                        <Route path="create" element={<AdminCratePlan />} />
                        <Route path=":planId" element={<AdminPlan />} />
                    </Route>
                    <Route path="gallery">
                        <Route path=":galleryid" element={<AdminGallery />} />
                        <Route path="create" element={<AdminCreateGallery />} />
                    </Route>
                </Route>
                <Route path="dashboard">
                    <Route index element={<Dashboard />}></Route>
                    <Route path="paint">
                        <Route path=":id" element={<PaintDashboard />} />

                        <Route path="pay">
                            <Route path=":id" element={<PayPaint />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="gallery">
                    <Route index element={<Gallery />} />
                    <Route path=":paintId">
                        <Route element={<Gallery />} index />
                        <Route path="compose" element={<ComposeGallery />} />
                    </Route>
                </Route>
                <Route path="gallerys">
                    <Route path=":biggalleryid" element={<BigGallery />} />
                </Route>
                <Route path="plans">
                    <Route index element={<Plans />} />
                    <Route path=":id" element={<BuyPlan />} />R
                </Route>
                <Route path="auth">
                    <Route index element={<Auth />} />
                    <Route path="confirm">
                        <Route path=":confirmToken" element={<AuthConfirm />} loader={AuthConfirmLoader} />
                    </Route>
                    <Route path="rest-password">
                        <Route path=":resetToken" element={<RestPassword />} loader={RestPasswordLoader} />
                    </Route>
                </Route>
                <Route path="about" element={<About />} />
                <Route path="paint">
                    <Route index element={<NotFound />} />
                    <Route path=":id">
                        <Route index element={<Paint />} loader={PaintLoader} />
                        <Route path=":imageID" element={<Paint />} loader={PaintLoader} />
                        <Route path="compose" element={<Compose />} />
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
        </Route>
    )
);

export default Routes;
