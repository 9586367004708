import { config } from "../config";
export const paint: TPaintBase = {
    name: "default",
    config: config,
    gallery: {
        isonly: false,
        storages: [
            {
                question: "?? test ??",
                label: "test",
                images: [],
                max_images: 10,
                id: null,
                status: "active",
            },
        ],
    },
    user: null,
    compose: {
        max_images: 100,
    },
};
