import React from "react";
import TPlan from "../../../@types/plan";
import { useNavigate } from "react-router-dom";
const PlanCard = (props: { plans: TPlan[] }) => {
    const navigate = useNavigate();
    const { plans } = props;
    const dateFormateur = Intl.DateTimeFormat("en-US", { dateStyle: "medium", timeStyle: "short" });
    const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

    return (
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            plan name
                        </th>
                        <th scope="col" className="py-3 px-6">
                            url
                        </th>

                        <th scope="col" className="py-3 px-6">
                            prix
                        </th>
                        <th scope="col" className="py-3 px-6">
                            create at
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {plans.map((plan) => (
                        <tr
                            key={plan.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                            onClick={(event) => {
                                navigate(`/admin/plan/${plan.id}`);
                            }}
                        >
                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {plan.name}
                            </th>
                            <td className="py-4 px-6">
                                <a href={`/plans/${plan.id}`} target="_blank" rel="noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                    {plan.name}
                                </a>
                            </td>
                            <td className="py-4 px-6">{currencyFormatter.format(plan.price)}</td>
                            <td className="py-4 px-6">{dateFormateur.format(new Date(plan.create_at))}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PlanCard;
