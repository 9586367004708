import { useCallback, useEffect, useReducer, useRef } from "react";
import _config from "../../config";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "sonner";
import { TimageStroke } from "../../@types/image";

import { Errors } from "../../utils/error";
import { Path } from "../../constants";
import Loader from "../../components/loader";
import NotFound from "../NotFound";
import { SyntheticEvent } from "react";
import FinishCompose from "../../components/finish-compose";

const compose_config = {
    svg: {
        width: 500,
        height: 500,
    },
    image: {
        width: 100,
        height: 100,
    },
};
interface ImageData {
    date: string;
    username?: string;
    id: string;
    elements: JSX.Element[];
    strokes: CanvasStorage[];
}
interface ComposeImageData extends ImageData {
    position: Position;
    angle: number;
    size: Size;
}

interface PaintData {
    name: string;
    config: Config;
    gallery: {
        images: TimageStroke[];
    };
}

namespace Actions {
    export type loading = {
        type: "loading";
        isloading: boolean;
    };
    export type paint = {
        type: "paint";
        paint: PaintData;
    };
    export type image = {
        type: "image";
        images: ImageData[];
    };
    export type dragstart = {
        type: "drag-start";
        firsttouch: Position;
        position: Position;
        image: ImageData;
        size: Size;
    };
    export type dragmove = {
        type: "drag-move";
        position: Position;
    };
    export type dragnull = {
        type: "drag-null";
    };
    export type newcomposeimage = {
        type: "new-compose-image";
        image: ComposeImageData;
        select_image_index: number;
    };
    export type newselectimage = {
        type: "new-image-select";
        firstposition: Position;
        position: Position;
        index: number;
        mousedown: boolean;
        size: Size;
    };
    export type rotateImage = {
        type: "rotate-image";
    };
    export type removeImage = {
        type: "remove-image";
    };
    export type finish = {
        type: "finish";
        image_id: string;
    };
    export type finishNull = {
        type: "finish-null";
    };
}
type TAction =
    | Actions.loading
    | Actions.paint
    | Actions.image
    | Actions.dragstart
    | Actions.dragnull
    | Actions.dragmove
    | Actions.newcomposeimage
    | Actions.newselectimage
    | Actions.rotateImage
    | Actions.removeImage
    | Actions.finish
    | Actions.finishNull;
type TState = {
    isLoading: boolean;
    paint: PaintData | null;
    size: Size;
    images: ImageData[];
    composes: ComposeImageData[];
    current_drag_element: {
        firstposition: Position;
        image: ImageData;
        position: Position;
        size: Size;
    } | null;
    current_image_select: {
        firstposition: Position;
        position: Position;
        index: number;
        mousedown: boolean;
        size: Size;
    } | null;
    finish: null | {
        imageid: string;
    };
};
type TReducer = (state: TState, action: TAction) => TState;
const reducer: TReducer = (state, action) => {
    if (action.type === "loading") {
        return {
            ...state,
            isLoading: action.isloading,
        };
    }
    if (action.type === "paint") {
        return { ...state, paint: action.paint };
    }
    if (action.type === "image") {
        return { ...state, images: action.images };
    }
    if (action.type === "drag-start") {
        const current_drag_element = {
            firstposition: action.firsttouch,
            image: action.image,
            position: action.position,
            size: action.size,
        };
        return { ...state, current_drag_element };
    }
    if (action.type === "drag-move" && state.current_drag_element) {
        const current_drag_element = { ...state.current_drag_element, position: action.position };
        return { ...state, current_drag_element };
    }
    if (action.type === "drag-null") {
        return { ...state, current_drag_element: null };
    }
    if (action.type === "new-compose-image") {
        const composes = [...state.composes, action.image];

        const current_image_select = {
            firstposition: {
                x: 0,
                y: 0,
            },
            index: composes.length - 1,
            mousedown: false,
            size: action.image.size,
            position: action.image.position,
        };

        return { ...state, composes, current_image_select };
    }
    if (action.type === "new-image-select") {
        const current_image_select: TState["current_image_select"] = {
            firstposition: action.firstposition,
            index: action.index,
            mousedown: action.mousedown,
            size: action.size,
            position: action.position,
        };
        const composes = [...state.composes];
        composes[action.index].position = action.position;
        return { ...state, composes, current_image_select };
    }
    if (action.type === "rotate-image" && state.current_image_select) {
        const composes = [...state.composes];
        composes[state.current_image_select.index].angle += 45;
        return { ...state, composes };
    }
    if (action.type === "remove-image" && state.current_image_select) {
        const composes = [...state.composes];
        composes.splice(state.current_image_select.index, 1);
        return { ...state, composes, current_image_select: null };
    }
    if (action.type === "finish") return { ...state, finish: { imageid: action.image_id } };
    if (action.type === "finish-null") return { ...state, finish: null };
    return { ...state };
};
const reducerInitiale: TState = {
    isLoading: true,
    paint: null,
    size: compose_config.svg,
    images: [],
    composes: [],
    current_drag_element: null,
    current_image_select: null,
    finish: null,
};

const Compose = () => {
    const [state, dispatch] = useReducer(reducer, reducerInitiale);
    const params = useParams();
    const svg = useRef<SVGSVGElement>(null);
    const getpositionformevent = (event: MouseEvent | TouchEvent) => {
        let position: Position;
        let offset: Position = { x: 0, y: 0 };
        let size: Size;

        if (!(event.target instanceof Element)) throw new Error("target is not typeof element");
        const target = event.target;
        if (event instanceof MouseEvent || event instanceof TouchEvent) {
            if (event instanceof MouseEvent) {
                position = {
                    x: event.clientX,
                    y: event.clientY,
                };
            } else {
                position = {
                    x: event.touches[0].clientX,
                    y: event.touches[0].clientY,
                };
            }

            const DOMRect = target.getBoundingClientRect();
            size = {
                width: DOMRect.width,
                height: DOMRect.height,
            };
            offset.x = position.x - DOMRect.x;
            offset.y = position.y - DOMRect.y;

            return { position, offset, size };
        } else throw new Error("native event is not typeof `MouseEvent` or `TouchEvent`");
    };
    const calculerPosition = (numbreOfColumn: number, sizeOfOneColumn: number, currentPosition: number) => {
        for (let i = 0; i <= numbreOfColumn; i++) {
            if (currentPosition < 0) return 0;
            if (i * sizeOfOneColumn - currentPosition > 0) return (i - 1) * sizeOfOneColumn;
        }
        throw new Error("error to find position");
    };
    const mousedown = (imageindex: number) => {
        const image = state.images[imageindex];
        return (event: SyntheticEvent<HTMLImageElement, TouchEvent | MouseEvent>) => {
            try {
                const { offset, position, size } = getpositionformevent(event.nativeEvent);
                dispatch({
                    type: "drag-start",
                    firsttouch: offset,
                    image,
                    position,
                    size,
                });
            } catch (error) {
                dispatch({
                    type: "drag-null",
                });
            }
        };
    };

    const mouseup = useCallback(
        (event: MouseEvent | TouchEvent) => {
            try {
                const { current_drag_element, paint, current_image_select } = state;
                if (!paint) return;
                if (current_drag_element) {
                    const { position } = current_drag_element;
                    const element = document.elementFromPoint(position.x, position.y);
                    if (element instanceof SVGSVGElement) {
                        const svginfo = element.getBoundingClientRect();
                        const x = position.x - svginfo.x;
                        const y = position.y - svginfo.y;
                        const cellWidth = state.size.width / paint.config.interatif.default_row;
                        const cellHeight = state.size.height / paint.config.interatif.default_row;

                        const positioninsvg: Position = {
                            x: calculerPosition(paint.config.interatif.default_row, cellWidth, x),
                            y: calculerPosition(paint.config.interatif.default_row, cellHeight, y),
                        };

                        const image: ComposeImageData = {
                            angle: 0,
                            position: positioninsvg,
                            strokes: current_drag_element.image.strokes,
                            elements: current_drag_element.image.elements,
                            id: current_drag_element.image.id,
                            date: current_drag_element.image.date,
                            size: current_drag_element.size,
                            username: current_drag_element.image.username,
                        };

                        dispatch({
                            type: "new-compose-image",
                            image,
                            select_image_index: state.composes.length,
                        });
                    }

                    dispatch({
                        type: "drag-null",
                    });
                    return;
                }
                if (current_image_select) {
                    const { firstposition, position, index, size } = current_image_select;
                    const cellWidth = state.size.width / paint.config.interatif.default_row;
                    const cellHeight = state.size.height / paint.config.interatif.default_row;

                    const svgposition: Position = {
                        x: calculerPosition(paint.config.interatif.default_row, cellWidth, position.x),
                        y: calculerPosition(paint.config.interatif.default_row, cellHeight, position.y),
                    };

                    dispatch({
                        type: "new-image-select",
                        firstposition: firstposition,
                        index: index,
                        mousedown: false,
                        position: svgposition,
                        size: size,
                    });
                    return;
                }
            } catch (error) {
                if (error instanceof Error) {
                    toast.error(error.message);
                }
            }
        },
        [state]
    );
    const mousemove = useCallback(
        (event: MouseEvent | TouchEvent) => {
            const { position } = getpositionformevent(event);
            if (state.current_drag_element) {
                dispatch({
                    type: "drag-move",
                    position,
                });
            }

            if (state.current_image_select && state.current_image_select.mousedown) {
                if (!svg.current) return;
                const { paint } = state;
                if (!paint) return;
                const svginfo = svg.current.getBoundingClientRect();
                const cellWidth = state.size.width / paint.config.interatif.default_row;
                const cellHeight = state.size.height / paint.config.interatif.default_row;

                const svgposition: Position = {
                    x: calculerPosition(paint.config.interatif.default_row, cellWidth, position.x - svginfo.x),
                    y: calculerPosition(paint.config.interatif.default_row, cellHeight, position.y - svginfo.y),
                };
                dispatch({
                    type: "new-image-select",
                    firstposition: state.current_image_select.firstposition,
                    index: state.current_image_select.index,
                    mousedown: state.current_image_select.mousedown,
                    position: svgposition,
                    size: state.current_image_select.size,
                });
            }
        },
        [state]
    );
    const rotate = useCallback(() => {
        const current_image_select = state.current_image_select;
        if (!current_image_select) return;
        dispatch({
            type: "rotate-image",
        });
    }, [state.current_image_select]);

    const removeImage = useCallback(() => {
        const current_image_select = state.current_image_select;
        console.log(current_image_select);

        if (!current_image_select) return;
        dispatch({
            type: "remove-image",
        });
    }, [state.current_image_select]);

    const svgmousedown = (composeimage: number) => {
        return (event: SyntheticEvent<SVGSVGElement, TouchEvent | MouseEvent>) => {
            try {
                if (!svg.current) return;
                const svginfo = svg.current.getBoundingClientRect();

                const { offset, position, size } = getpositionformevent(event.nativeEvent);
                const cellWidth = state.size.width / config.interatif.default_row;
                const cellHeight = state.size.height / config.interatif.default_row;
                const svgposition: Position = {
                    x: calculerPosition(paint.config.interatif.default_row, cellWidth, position.x - svginfo.x),
                    y: calculerPosition(paint.config.interatif.default_row, cellHeight, position.y - svginfo.y),
                };

                dispatch({
                    type: "new-image-select",
                    firstposition: offset,
                    position: svgposition,
                    index: composeimage,
                    mousedown: true,
                    size: size,
                });
            } catch (error) {}
        };
    };
    const render = () => {
        const strokes: CanvasStorage[] = [];
        const cellWidth = state.size.width / config.interatif.default_row;
        const cellHeight = state.size.height / config.interatif.default_row;
        for (let i = 0; i < state.composes.length; i++) {
            const compose = state.composes[i];
            const svgChild: TStroke[] = [];
            for (let i = 0; i < compose.strokes.length; i++) {
                const stroke = compose.strokes[i];
                if (stroke.type === "svg") continue;
                else {
                    svgChild.push({
                        type: stroke.type,
                        d: stroke.d,
                        size: stroke.size,
                        color: stroke.color,
                        position: stroke.position,
                        viewBox: stroke.viewBox,
                    });
                }
            }
            strokes.push({
                type: "svg",
                position: compose.position,
                storages: svgChild,
                viewBox: {
                    width: state.size.width,
                    height: state.size.height,
                },
                angle: compose.angle,
                cellsize: {
                    width: cellWidth,
                    height: cellHeight,
                },
            });
        }
        return strokes;
    };
    const post = async () => {
        try {
            const strokes = render();
            const paintid = params.id;
            if (!paintid) return toast.error("error to find paintid");
            const responce = await axios.post(`${_config.api_server_uri}${Path.Gallery.compose.post.replace(/:paintid/, paintid)}`, { strokes });
            if (responce.status !== 200) return toast.error(responce.data.message);
            const image = responce.data.image;
            dispatch({
                type: "finish",
                image_id: image,
            });
        } catch (error) {
            Errors.handler(error);
        }
    };
    useEffect(() => {
        const load = async () => {
            const paintid = params.id;
            if (!paintid) return toast.error("error to find paintid");
            const createSegment = (stroke: TStroke) => {
                if (stroke.type === "fill") return <path fill={stroke.color} d={stroke.d} fillRule="evenodd" clipRule="evenodd" />;
                return <path stroke={stroke.color} d={stroke.d} strokeWidth={stroke.size} strokeLinecap="round" fill="none" />;
            };
            try {
                const responce = await axios.get(`${_config.api_server_uri}${Path.Gallery.compose.get_data.replace(/:paintid/, paintid)}`);
                if (responce.status !== 200) return toast.error(responce.data.message);
                const paint: PaintData = responce.data.paint;
                dispatch({ type: "paint", paint });
                const { gallery } = paint;
                const images: ImageData[] = [];

                for (let i = 0; i < gallery.images.length; i++) {
                    const image = gallery.images[i];
                    const elements: JSX.Element[] = [];

                    for (let j = 0; j < image.strokes.length; j++) {
                        const stroke = image.strokes[j];

                        const { viewBox, position } = stroke;

                        elements.push(
                            <svg key={`${i}-${j}`} viewBox={`0 0 ${viewBox.width} ${viewBox.height}`} x={position.x} y={position.y}>
                                {stroke.type === "fill" && createSegment(stroke)}
                                {stroke.type === "stroke" && createSegment(stroke)}
                                {stroke.type === "svg" && stroke.storages.map((stroke) => createSegment(stroke))}
                            </svg>
                        );
                    }
                    images.push({
                        date: image.date,
                        elements: elements,
                        id: image.id,
                        username: image.username,
                        strokes: image.strokes,
                    });
                }

                dispatch({
                    type: "image",
                    images,
                });
            } catch (error) {
                Errors.handler(error);
            } finally {
                dispatch({ type: "loading", isloading: false });
            }
        };
        load();
    }, [params.id]);

    useEffect(() => {
        window.addEventListener("mouseup", mouseup);
        window.addEventListener("touchend", mouseup);

        window.addEventListener("mousemove", mousemove);
        window.addEventListener("touchmove", mousemove);

        return () => {
            window.removeEventListener("mouseup", mouseup);
            window.removeEventListener("touchend", mouseup);

            window.removeEventListener("mousemove", mousemove);
            window.removeEventListener("touchmove", mousemove);
        };
    }, [mousemove, mouseup]);
    const paintid = params.id;
    if (state.isLoading) return <Loader />;
    if (!state.paint || !paintid) return <NotFound />;
    const { paint, images } = state;
    const { config } = paint;

    return (
        <div className="h-screen w-screen flex select-none overflow-hidden">
            {state.current_drag_element && (
                <>
                    <div
                        className="absolute z-50 pointer-events-none"
                        style={{
                            top: `${state.current_drag_element.position.y - (state.current_drag_element.firstposition.y * compose_config.image.height) / state.current_drag_element.size.height}px`,
                            left: `${state.current_drag_element.position.x - (state.current_drag_element.firstposition.x * compose_config.image.width) / state.current_drag_element.size.width}px`,
                            width: `${compose_config.image.width}px`,
                            height: `${compose_config.image.height}px`,
                        }}
                    >
                        <img draggable="false" src={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, state.current_drag_element.image.id)}?width=500&height=500`} alt="" />
                    </div>
                </>
            )}
            {state.finish && (
                <FinishCompose
                    close={() => {
                        dispatch({
                            type: "finish-null",
                        });
                    }}
                    image_url={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, state.finish.imageid)}?width=500&height=500`}
                    paintId={paintid}
                />
            )}
            {/* tools container */}
            <div className="flex justify-end items-center px-5 flex-1">
                <div className="bg-gray-800 max-h-[500px] px-5 py-4 rounded-2xl flex gap-4 flex-col">
                    <div className={`${state.current_image_select ? "bg-white" : "bg-white/80"} p-5 rounded-xl flex justify-center items-center cursor-pointer`} title="rotate" onClick={rotate}>
                        <svg className="w-8 h-8 text-gray-700" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M17 9a1 1 0 0 0-1 1 6.994 6.994 0 0 1-11.89 5H7a1 1 0 0 0 0-2H2.236a1 1 0 0 0-.585.07c-.019.007-.037.011-.055.018-.018.007-.028.006-.04.014-.028.015-.044.042-.069.06A.984.984 0 0 0 1 14v5a1 1 0 1 0 2 0v-2.32A8.977 8.977 0 0 0 18 10a1 1 0 0 0-1-1ZM2 10a6.994 6.994 0 0 1 11.89-5H11a1 1 0 0 0 0 2h4.768a.992.992 0 0 0 .581-.07c.019-.007.037-.011.055-.018.018-.007.027-.006.04-.014.028-.015.044-.042.07-.06A.985.985 0 0 0 17 6V1a1 1 0 1 0-2 0v2.32A8.977 8.977 0 0 0 0 10a1 1 0 1 0 2 0Z" />
                        </svg>
                    </div>
                    <div className={`bg-white p-5 rounded-xl flex justify-center items-center cursor-pointer`} title="post image" onClick={post}>
                        <svg className="w-8 h-8 text-gray-700" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.02002 21.27H39.77C39.77 21.27 39.797 17.736 42.52 16.27C45.77 14.52 49.712 15.821 51.27 17.52C54.02 20.52 53.432 24.108 51.52 26.02C49.52 28.02 46.27 28.52 46.27 28.52V82.52"
                                stroke="black"
                                strokeWidth="4"
                                strokeMiterlimit="10"
                            />
                            <path d="M6.52002 25.52H41.52V82.77" stroke="black" strokeMiterlimit="10" />
                            <path d="M5.27002 29.02H38.27V83.04" stroke="black" strokeWidth="2" strokeMiterlimit="10" />
                            <path d="M3.38501 33.905H33.385V82.04" stroke="black" strokeWidth="3" strokeMiterlimit="10" />
                            <path d="M41.52 25.52L33.02 34.77" stroke="black" strokeMiterlimit="10" />
                            <path d="M57.27 57.02L66.02 63.27" stroke="black" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M65.038 44.217L75.252 47.573" stroke="black" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M66.519 31.433L77.271 31.357" stroke="black" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M58.887 17.35L68.903 13.44" stroke="black" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M33.516 11.085L30.668 4.454" stroke="black" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
                            <path d="M49.0649 8.521L49.1319 4.02" stroke="black" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" />
                        </svg>
                    </div>

                    <div
                        className={`${
                            state.current_image_select ? "bg-white hover:bg-red-200" : "bg-white/80"
                        } p-5 rounded-xl flex justify-center items-center cursor-pointer group  transition-all hover:scale-[101%]"`}
                        title="delete"
                        onClick={removeImage}
                    >
                        <svg
                            className={`w-8 h-8  ${state.current_image_select ? "group-hover:text-red-600" : "text-gray-700"} transition-all`}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 18 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div className=" flex justify-center items-center flex-[2]">
                <svg
                    ref={svg}
                    className="border-solid border-[1px] border-black"
                    style={{
                        minWidth: state.size.width,
                        minHeight: state.size.height,
                    }}
                    width={state.size.width}
                    height={state.size.height}
                    viewBox={`0 0 ${compose_config.svg.width} ${compose_config.svg.height}`}
                >
                    {config.interatif.row
                        ? Array.from({ length: config.interatif.default_row }).map((_, index) => {
                              const width = config.interatif.default_size.width;
                              const row_size = width / config.interatif.default_row;
                              return <line key={index} x1={row_size * index} y1="0" y2={width} x2={row_size * index} stroke="black" />;
                          })
                        : null}
                    {state.paint.config.interatif.row
                        ? Array.from({ length: config.interatif.default_row }).map((_, index) => {
                              const height = config.interatif.default_size.height;

                              const row_size = height / config.interatif.default_row;
                              return <line x1="0" y1={row_size * index} y2={row_size * index} x2={height} stroke="black" key={index} />;
                          })
                        : null}
                    {state.composes.map((compose, index) => {
                        const { position, elements, size, angle } = compose;
                        const { config } = paint;
                        const cellWidth = state.size.width / config.interatif.default_row;
                        const cellHeight = state.size.height / config.interatif.default_row;
                        const isActive = !!state.current_image_select && state.current_image_select.index === index;

                        const transform = `rotate(${angle} ${size.width / 2} ${size.height / 2})`;

                        return (
                            <svg
                                onMouseDown={svgmousedown(index)}
                                onTouchStart={svgmousedown(index)}
                                key={index}
                                x={position.x}
                                y={position.y}
                                viewBox={`0 0 ${size.width} ${size.height}`}
                                width={`${cellWidth}px`}
                                height={`${cellHeight}px`}
                                className={`${state.current_drag_element ? "pointer-events-none" : ""}`}
                            >
                                <g width={`${cellWidth}px`} height={`${cellHeight}px`} transform={transform}>
                                    {elements}
                                </g>
                                {isActive && <rect width={`${size.width}px`} height={`${size.height}px`} x={0} y={0} strokeWidth={15} stroke="#00F" fill="none" />}
                            </svg>
                        );
                    })}
                </svg>
            </div>
            <div className="flex-1 flex justify-center items-center px-5">
                <div className="bg-gray-800 w-[200px] sm:w-[300px] max-h-[500px] px-5 py-4 rounded-xl flex gap-4 flex-col">
                    <span className="text-center text-white">Images</span>
                    <div className="flex flex-col gap-2 overflow-auto">
                        {images.map((image, index) => (
                            <img
                                draggable="false"
                                key={image.id}
                                onMouseDown={mousedown(index)}
                                onTouchStart={mousedown(index)}
                                className="w-full bg-white select-none"
                                src={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, image.id)}?width=500&height=500`}
                                alt={"image " + image.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Compose;
