import { useContext } from "react";
import { Tpage } from "../../@types/pages";
import { Auth } from "../../contexts/auth";

export const pages: Tpage[] = [
    {
        name: "HOME",
        path: "/",
        permission: {
            admin: false,
            auth: false,
            notauth: false,
        },
    },
    {
        name: "ACCOUNT",
        path: "/auth",
        permission: {
            admin: false,
            auth: true,
            notauth: false,
        },
    },
    {
        name: "DASHBOARD",
        path: "/dashboard",
        permission: {
            admin: false,
            auth: true,
            notauth: false,
        },
    },
    {
        name: "ADMIN",
        path: "/admin",
        permission: {
            admin: true,
            auth: true,
            notauth: false,
        },
    },
    {
        name: "LOGOUT",
        path: "/logout",
        permission: {
            admin: false,
            auth: true,
            notauth: false,
        },
        onClick: (navigate) => {
            localStorage.removeItem("token");
            window.location.href = "/login";
        },
    },

    {
        name: "LOGIN",
        path: "/login",
        permission: {
            admin: false,
            auth: false,
            notauth: true,
        },
    },
    {
        name: "REGISTER",
        path: "/register",
        permission: {
            admin: false,
            auth: false,
            notauth: true,
        },
    },
];
