const loadSVG: LoadSVG = async (uri) => {
    return new Promise(async (resolve) => {
        const res = await fetch(uri);
        const svgString = await res.text();
        console.log(res.headers.get('Content-Type'));

        if (
            res.headers.get('Content-Type') === "image/svg+xml" ||
            res.headers.get('Content-Type') === "image/svg+xml; charset=utf-8"
        ) {
            resolve(svgString);
        } else {
            resolve(null);
        }
    });
}
export default loadSVG