import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Auth } from "../../../../../contexts/auth";
import { Path } from "../../../../../constants";
import _config from "../../../../../config";
import { toast } from "sonner";

const PaintPage = (props: { paint: TPaintBase; onChange: (paint: TPaintBase) => void }) => {
    const { paint, onChange } = props;
    const [users, setUesrs] = useState<User[]>([]);
    const { token } = useContext(Auth);
    // const navigate = useNavigate();
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responce = await axios.get(`${_config.api_server_uri}${Path.Admin.user.index}`, { headers: { Authorization: token } });
                if (responce.status !== 200) return toast.error(responce.data.message);
                setUesrs(responce.data.users);
            } catch (error) {
                if (error instanceof AxiosError && error.response?.data) toast.error(error.response.data.message);
                else if (error instanceof Error) toast.error(error.message);
                else toast.error("unkown error");
            }
        };
        fetchUser();
    }, [token]);
    useEffect(() => {}, []);

    return (
        <>
            <div>
                <label htmlFor="paint-name" className="flex flex-col text-white">
                    <span className="cursor- ">name</span>
                    <input
                        type="text"
                        id="paint-name"
                        value={paint.name}
                        className="border my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        onChange={(event) => {
                            onChange({ ...paint, name: event.target.value });
                        }}
                    />
                </label>
            </div>
            {/* <div>
                <label htmlFor="paint-status" className="flex flex-col text-white">
                    <span className="cursor-pointer">status</span>
                    <select
                        id="paint-status"
                        className="border  my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        value={paint.status}
                        onChange={(event) => {
                            if (StatusList.some((status) => event.target.value === status)) {
                                onChange({ ...paint, status: event.target.value as any });
                            }
                        }}
                    >
                        {StatusList.map((status, index) => (
                            <option key={index} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </label>
            </div> */}
            <div>
                <label htmlFor="paint-owner" className="flex flex-col text-white">
                    <span className="cursor-pointer">owner</span>
                    <select
                        id="paint-owner"
                        className="border my-3 text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        value={`${paint.user?.id}`}
                        onChange={(event) => {
                            const user = users.find((user) => user.id === event.target.value);
                            if (user) {
                                onChange({
                                    ...paint,
                                    user,
                                });
                            }
                        }}
                    >
                        {users.map((user) => (
                            <option key={user.id} value={user.id} className="py-5">
                                {user.email}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            {/* <div className="w-full mt-5">
                <button type="button" className="bg-red-600 text-white px-5 py-2 rounded-md" onClick={DeletePaint}>
                    delete this paint
                </button>
            </div> */}
        </>
    );
};
export default PaintPage;
