import NavBar from "../../components/navbar";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import PaintCard from "./paint-card";
import { Auth } from "../../contexts/auth";

const Dashboard = () => {
    const { user } = useContext(Auth);
    return (
        <div className="h-full w-screen dark:bg-gray-800 bg-gray-300 overflow-x-hidden">
            <NavBar user={user} />
            <div className="p-7 dark:bg-gray-800 bg-gray-300">
                {user ? (
                    <>
                        <div className="w-full h-14 flex justify-end items-center">
                            <NavLink className="w-28 h-12 bg-green-600 text-gray-200 rounded flex justify-center items-center" draggable="false" to="/plans">
                                new mural
                            </NavLink>
                        </div>
                        {user.role === "customer" && user.status === "need-confirm" ? (
                            <div className="w-full mt-5">
                                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-900 dark:text-red-400" role="alert">
                                    to need to confirm your account, check your email
                                </div>
                            </div>
                        ) : null}
                        {user.paints.length === 0 ? (
                            <div className="w-full h-auto dark:text-white flex justify-center items-center p-16">no application</div>
                        ) : (
                            <div className="mt-5 ">{<PaintCard paints={user.paints} />}</div>
                        )}
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default Dashboard;
