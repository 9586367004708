import { TBIG_GALLERY_BASE } from "../../../@types/big_gallery";

export const big_gallery_base: TBIG_GALLERY_BASE = {
    name: "big-gallery",
    gallerys: [],
    config: {
        image: {
            background_color: "#FFF",
            line_count: 2,
        },
    },
};
