import { z } from "zod";
import { zConfig } from "./config";
const zName = z
    .string({
        required_error: "`name` is required",
        invalid_type_error: "`name` is typeof `string`",
    })
    .regex(/^[a-zA-Z0-9- ]+$/, { message: "paint name is not valid" });
export const zPaintEdit = z.object({
    name: zName.optional(),
    status: z.enum(["active", "deactivate", "require_payment", "expiry"]).optional(),

    config: zConfig.optional(),
    gallery: z
        .object(
            {
                isonly: z.boolean({
                    required_error: "`gallery.isonlyl` is required",
                    invalid_type_error: "`gallery.isonly` is typeof `boolean`",
                }),
                storages: z
                    .object(
                        {
                            question: z
                                .string({
                                    required_error: "`gallery.storages.$.question` is required",
                                    invalid_type_error: "`gallery.storages.$.question` is typeof `string`",
                                })
                                .nullable(),
                            label: z.string({
                                required_error: "`gallery.storages.$.label` is required",
                                invalid_type_error: "`gallery.storages.$.label` is typeof `string`",
                            }),
                            status: z.enum(["active", "deactive"], {
                                required_error: "`gallery.storages.$.status` is required",
                                invalid_type_error: "`gallery.storages.$.status` is typeof `enum(active,deactive)`",
                            }),

                            id: z.string({ required_error: "`gallery.storages.$.id` is required", invalid_type_error: "`gallery.storages.$.id` is typeof `string`" }).nullable(),
                            max_images: z.number({ required_error: "`gallery.storages.$.max_images` is required", invalid_type_error: "`gallery.storages.$.max_images   ` is typeof `string`" }),
                        },
                        {
                            required_error: "`gallery.storages` is required",
                            invalid_type_error: "`gallery.storages` is typeof `object array`",
                        }
                    )
                    .array(),
            },
            {
                required_error: "`gallery` is required",
                invalid_type_error: "`gallery` is typeof `object`",
            }
        )
        .optional(),
    compose: z
        .object(
            {
                gallery: z.object({
                    max_images: z.number({
                        required_error: "`compose.max_images` is required",
                        invalid_type_error: "`compose.max_images` is typeof `number`",
                    }),
                }),
            },
            {
                required_error: "`compose` is required",
                invalid_type_error: "`compose` is typeof `object`",
            }
        )
        .optional()
        .nullable(),
    user: z
        .object({
            id: z.string({
                required_error: "`user.id` is required",
                invalid_type_error: "`user.id` is typeof `string`",
            }),
        })
        .optional(),
});
