import { AxiosError } from "axios";
import { ZodError } from "zod";
import { toast } from "sonner";
export namespace Errors {
    export const handler = (error: unknown) => {
        if (error instanceof AxiosError && error.response?.data.message) {
            toast.error(error.response?.data.message);
        } else if (error instanceof Error) {
            toast.error(error.message);
        } else if (error instanceof ZodError) {
            const message = error.errors.map((zodissue) => zodissue.message).join(", ");
            toast.error(message);
        } else {
            toast.error("unknown error");
        }
    };
}
