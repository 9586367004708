import { useNavigate } from "react-router-dom";
import { TBIG_GALLERY } from "../../../@types/big_gallery";

const GalleryCard = (props: { big_gallerys: TBIG_GALLERY[] }) => {
    const { big_gallerys } = props;
    const navigate = useNavigate();

    return (
        <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">
                            gallery name
                        </th>
                        <th scope="col" className="py-3 px-6">
                            url
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {big_gallerys.map((big_gallery) => (
                        <tr
                            key={big_gallery.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                            onClick={(event) => {
                                navigate(`/admin/gallery/${big_gallery.id}`);
                            }}
                        >
                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {big_gallery.name}
                            </th>
                            <td className="py-4 px-6">
                                <a href={`/gallerys/${big_gallery.id}`} target="_blank" rel="noreferrer" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                    {big_gallery.name}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
export default GalleryCard;
