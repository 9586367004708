import axios from "axios";
import config from "../../config";
import { Path } from "../../constants";
const stripePk: (token: string) => Promise<string | null> = async (token) => {
    try {
        const res = await axios.post(`${config.api_server_uri}${Path.Dashboard.stripe.public_key}`, {}, { headers: { Authorization: token } });

        return res.data.stripe_key;
    } catch (err) {
        return null;
    }
};

export default stripePk;
