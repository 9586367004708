const paths = {
    creditImagePath: "./img/pop_art_logo.jpg",
    imageURls: [],
    undo_icon: "./img/icons/iconsketch-undo.svg",
    clear_icon: "./img/icons/iconsketch-clear.svg",
    marker_icon: {
        m02: "./img/icons/iconsketch-brush02.svg",
        m03: "./img/icons/iconsketch-brush03.svg",
        m04: "./img/icons/iconsketch-brush04.svg",
        m05: "./img/icons/iconsketch-brush05.svg",
    },
    eraser_icon: "./img/icons/iconsketch-eraser.svg",
    bucket_icon: "./img/icons/iconsketch-bucket.svg",
    gallery_icon: "./img/icons/iconsketch-gallery.svg",
    create_room_icon: "./img/icons/iconsketch-creater-room.svg",
    share_icon: "./img/icons/iconsketch-share.svg",
    save_icon: "./img/icons/iconsketch-save-socket.svg",
    info_icon: "./img/icons/iconsketch-info.svg",
};
export default paths;
/**
        "./img/svg/lettre/a.svg",
        "./img/svg/lettre/b.svg",
        "./img/svg/lettre/c.svg",
        "./img/svg/lettre/d.svg",
        "./img/svg/lettre/e.svg",
        "./img/svg/lettre/f.svg",
        "./img/svg/lettre/g.svg",
        "./img/svg/lettre/h.svg",
        "./img/svg/lettre/i.svg",
        "./img/svg/lettre/j.svg",
        "./img/svg/lettre/k.svg",
        "./img/svg/lettre/l.svg",
        "./img/svg/lettre/m.svg",
        "./img/svg/lettre/n.svg",
        "./img/svg/lettre/o.svg",
        "./img/svg/lettre/p.svg",
        "./img/svg/lettre/q.svg",
        "./img/svg/lettre/r.svg",
        "./img/svg/lettre/s.svg",
        "./img/svg/lettre/t.svg",
        "./img/svg/lettre/u.svg",
        "./img/svg/lettre/v.svg",
        "./img/svg/lettre/w.svg",
        "./img/svg/lettre/x.svg",
        "./img/svg/lettre/y.svg",
        "./img/svg/lettre/z.svg", */