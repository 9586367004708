import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import config from "../../config";
import { NavLink, useNavigate } from "react-router-dom";
import { Path } from "../../constants";
import { Auth } from "../../contexts/auth";

const Login = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<null | { error: string; modes: ("email" | "password")[] }>(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { user, reload } = useContext(Auth);

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [navigate, user]);
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let returnUrl = "/";
        const searchParams = new URLSearchParams(window.location.search);
        const planId = searchParams.get("planId");
        const paintId = searchParams.get("paintId");
        if (planId) {
            returnUrl = `/plans/${planId}`;
        } else if (paintId) {
            returnUrl = `/dashboard/paint/${paintId}`;
        }
        try {
            const responce = await axios.post(`${config.api_server_uri}${Path.Auth.signin}`, { password, email });

            if (responce.status === 200) {
                if (responce.data.token) {
                    localStorage.setItem("token", responce.data.token);
                    reload();
                    navigate(returnUrl);
                } else {
                    setErrorMessage({ error: "error to get token", modes: [] });
                }
            } else {
                setErrorMessage({
                    error: responce.data.message,
                    modes: responce.data.errorMode,
                });
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response) {
                    setErrorMessage({ error: error.response.data.message, modes: error.response.data.errorMode });
                } else {
                    setErrorMessage({ error: "unknown error", modes: [] });
                }
            }
        }
    };
    return (
        <section className="bg-gray-300 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
                <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-8 h-8 mr-2" src=".\img\icon.jpg" alt="logo" draggable="false" />
                    Anagram
                </div>
                {errorMessage ? (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-10 py-3 rounded relative mb-8" role="alert">
                        <span className="block sm:inline">{errorMessage.error}</span>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <title>Close</title>
                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                            </svg>
                        </span>
                    </div>
                ) : null}
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Sign in to your account</h1>
                        <form className="space-y-4 md:space-y-6" action="#" onSubmit={onSubmit}>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Your email
                                </label>
                                <input
                                    value={email}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                        setErrorMessage(null);
                                    }}
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="name@company.com"
                                    required={true}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Password
                                </label>
                                <input
                                    value={password}
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                        setErrorMessage(null);
                                    }}
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="••••••••"
                                    className="bg-gray-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required={true}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <NavLink to="./forgot-password" className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                                    Forgot password?
                                </NavLink>
                            </div>
                            <button
                                type="submit"
                                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Sign in
                            </button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Don't have an account yet?{" "}
                                <NavLink to={`/register${window.location.search}`} className="font-medium text-blue-600 hover:underline dark:text-blue-500">
                                    Sign up
                                </NavLink>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
// export const loader: LoaderFunction = async (data) => {
//     const isLog = await isLogin();
//     if (isLog) return redirect("/dashboard");
//     else return null;
// };
