import { FormEvent, useState, useContext } from "react";
// import { , useLoaderData } from "react-router-dom";
// import isLogin from "../../utils/isLogin";
import NavBar from "../../components/navbar";
import axios, { AxiosError } from "axios";
import config from "../../config";
import { Auth as authContext } from "../../contexts/auth";
import NotFound from "../NotFound";
import { Path } from "../../constants";

const Auth = () => {
    // const { user } = useLoaderData() as { user: User };
    const { user, token } = useContext(authContext);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [changePasswordSubmit, setChangePasswordSubmit] = useState(false);

    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleChangePassword = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setChangePasswordSubmit(true);

        if (newPassword !== confirmNewPassword) {
            setChangePasswordSubmit(false);
            return setError("new password and confirm new password not match");
        }
        if (newPassword.length < 8) {
            setChangePasswordSubmit(false);
            return setError("new password too short");
        }

        try {
            const response = await axios.post(`${config.api_server_uri}${Path.Auth.changePassword}`, { currentPassword, newPassword }, { headers: { authorization: token } });
            if (response.status === 200) {
                const body: { code: number; message: string } = response.data;

                setMessage(body.message);

                setConfirmNewPassword("");
                setNewPassword("");
                setCurrentPassword("");
            } else {
                const body: { code: number; message: string } = response.data;

                setError(body.message);
            }
            setChangePasswordSubmit(false);
        } catch (error) {
            if (error instanceof AxiosError) {
                const response = error.response;
                if (response) {
                    const body: { code: number; message: string } = response.data;
                    setError(body.message);
                    setChangePasswordSubmit(false);
                } else {
                    setError("error to join server");
                    setChangePasswordSubmit(false);
                }
            } else {
                setError("error to join server");
                setChangePasswordSubmit(false);
            }
        }
    };

    if (!user) return <NotFound />;

    return (
        <div className="h-screen w-screen dark:bg-gray-800 dark:text-white  bg-gray-300">
            <div className="h-screen w-screen dark:bg-gray-800 bg-gray-300 text-white">
                <NavBar user={user} />
                <div className="w-full flex justify-center items-center mt-10">
                    <div className="w-1/2">
                        <div className="w-full h-auto">
                            {message ? (
                                <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col mb-7">
                                    <div className="w-full sm:w-1/2">
                                        <div
                                            id="alert-border-3"
                                            className="flex p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-900 dark:border-green-900 rounded-xl"
                                        >
                                            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            <div className="ml-3 text-sm font-medium">{message}</div>
                                            <button
                                                type="button"
                                                className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                                                onClick={() => {
                                                    setError(null);
                                                    setMessage(null);
                                                }}
                                            >
                                                <span className="sr-only">Dismiss</span>
                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {error ? (
                                <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col mb-7">
                                    <div className="w-full sm:w-1/2">
                                        <div
                                            id="alert-border-3"
                                            className="flex p-4 mb-4 text-red-900 border-t-4 border-red-300 bg-red-50 dark:text-red-200 dark:bg-red-900 dark:border-red-800 rounded-xl"
                                        >
                                            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            <div className="ml-3 text-sm font-medium">{error}</div>
                                            <button
                                                type="button"
                                                className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                                                onClick={() => {
                                                    setError(null);
                                                    setMessage(null);
                                                }}
                                            >
                                                <span className="sr-only">Dismiss</span>
                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="mb-6">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Your email
                            </label>
                            <input
                                className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                type="email"
                                value={user.email}
                                disabled
                                required
                            />
                        </div>
                        <form onSubmit={handleChangePassword}>
                            <div className="text-2xl font-bold mb-5 text-black dark:text-white ">Change password</div>
                            <div className="ml-5">
                                <div className="mb-6">
                                    <label htmlFor="current-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        current password
                                    </label>
                                    <input
                                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        type="password"
                                        id="current-password"
                                        value={currentPassword}
                                        onChange={(event) => setCurrentPassword(event.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="new-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        new password
                                    </label>
                                    <input
                                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        type="password"
                                        id="new-password"
                                        value={newPassword}
                                        onChange={(event) => setNewPassword(event.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="confirm-new-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        confirm new password
                                    </label>
                                    <input
                                        className="bg-whitebg-gray-300 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        type="password"
                                        id="confirm-new-password"
                                        value={confirmNewPassword}
                                        onChange={(event) => setConfirmNewPassword(event.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="w-full flex justify-center items-center">
                                <div className="w-1/2">
                                    <button
                                        type="submit"
                                        className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    >
                                        {changePasswordSubmit ? (
                                            <div className="w-full flex items-center justify-center">
                                                <svg
                                                    aria-hidden="true"
                                                    className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-900"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                            </div>
                                        ) : (
                                            <>change password</>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
// export const loader: LoaderFunction = async () => {
//     const isLog = await isLogin();
//     if (isLog) return isLog
//     else return redirect('/login');
// };
