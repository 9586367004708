import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { pages } from "../../utils/pages";

const NavBar = (props: { user: User | null }) => {
    const [showMeunu, setShowMeunu] = useState<boolean>(false);
    const navigate = useNavigate();
    const { user } = props;
    return (
        <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded  bg-[#86C825]">
            <div className="container flex flex-wrap items-center justify-between mx-auto">
                <a href="http://digital-mural.com/" className="flex items-center">
                    <img className="w-8 h-8 mr-2" src=".\img\icon.jpg" alt="logo" draggable="false" />
                    <span className="self-center text-xl font-semibold whitespace-nowrap text-white">ANAGRAM</span>
                </a>
                <button
                    onClick={(event) => setShowMeunu((value) => !value)}
                    data-collapse-toggle="navbar-default"
                    type="button"
                    className="inline-flex items-center p-2 ml-3 text-sm rounded-lg md:hidden focus:outline-none hover:bg-teal-500"
                    aria-controls="navbar-default"
                    aria-expanded="false"
                >
                    <span className="sr-only">Open main menu</span>

                    <svg className="w-6 h-6 text-teal-600" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
                <div className={showMeunu ? "w-full md:block md:w-auto" : "hidden w-full md:block md:w-auto"} id="navbar-default">
                    <ul className="flex flex-col p-4 mt-4  rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 ">
                        {pages.map((page, index) => {
                            if (page.permission.auth && !user) return null;
                            if (page.permission.admin && (!user || user.role !== "admin")) return null;
                            if (page.permission.notauth && user) return null;
                            return (
                                <li key={index}>
                                    <NavLink
                                        to={page.path}
                                        className={({ isActive }) =>
                                            isActive
                                                ? "block py-2 pl-3 pr-4 rounded md:bg-transparent  md:p-0 text-white"
                                                : "block py-2 pl-3 pr-4 rounded md:bg-transparent  md:p-0 text-black hover:text-white"
                                        }
                                        onClick={(event) => {
                                            if (!page.onClick) return;
                                            event.preventDefault();
                                            page.onClick(navigate);
                                        }}
                                    >
                                        {page.name}
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
