import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Plan from "../../components/plan";
import config from "../../config";
import TPlan from "../../@types/plan";
import { Path } from "../../constants";

const Plans = () => {
    const [plans, setPlans] = useState<TPlan[] | null>(null);
    const navigator = useNavigate();
    const loadPlan = async () => {
        const responce = await axios.get(`${config.api_server_uri}${Path.Dashboard.plan.index}`);
        if (responce.status === 200) {
            setPlans(responce.data.plans);
        }
    };
    useEffect(() => {
        loadPlan();
    }, []);
    useEffect(() => {
        if (plans && plans.length === 1) {
            const plan = plans[0];
            navigator(`/plans/${plan.id}`);
        }
    }, [navigator, plans]);
    return (
        <div className="w-full h-full bg-white dark:bg-gray-900 overflow-x-hidden">
            <div className="bg-white dark:bg-gray-900 h-auto ">
                <h2 className="text-center text-6xl py-12 text-black dark:text-white">Plans</h2>
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 grid grid-cols-1 sm:grid-cols-2 ">{plans && plans.map((plan) => <Plan key={plan.id} {...plan} />)}</div>
            </div>
        </div>
    );
};

export default Plans;
