import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import NavBar from "../../components/navbar";
import { useNavigate } from "react-router-dom";
import PaintComponent from "./components/paint";
import PlanComponent from "./components/plan";
import { Upload as UploadComponent } from "./components/upload";
import { Gallery as GalleryComponent } from "./components/gallery";
import { useMessage } from "../../hook/message/inedx";
import { useAuth } from "../../hook/auth";
import NotFound from "../NotFound";
type Pages = "paint" | "plan" | "upload" | "big-gallery";

const Admin = () => {
    const { user } = useAuth();
    const hashPage = (window.location.hash.replace("#", "") || "paint") as any;
    const { element, closeMessage, createMessage } = useMessage();

    const [page, setPages] = useState<Pages>(hashPage);
    const navigate = useNavigate();

    useEffect(() => {
        if (hashPage === "paint" || hashPage === "plan" || hashPage === "upload" || hashPage === "big-gallery") setPages(hashPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        navigate(`#${page}`);
    }, [page, navigate]);

    if (!user) return <></>;
    if (user.role === "customer") return <NotFound />;

    return (
        <div className="h-full w-screen dark:bg-gray-800 bg-gray-300 overflow-x-hidden">
            <NavBar user={user} />
            {element}

            <div className="p-7 dark:bg-gray-800 bg-gray-300">
                <div className="w-full h-14 flex justify-end items-center gap-2">
                    <NavLink className="px-5 h-12 bg-green-600 text-white rounded flex justify-center items-center" draggable="false" to="/admin/paint/create">
                        new mural
                    </NavLink>
                    <NavLink className="px-5 h-12 bg-blue-600 text-white rounded flex justify-center items-center" draggable="false" to="/admin/plan/create">
                        new plans
                    </NavLink>
                    <NavLink className="h-12 bg-gray-600 text-white rounded flex justify-center items-center px-5" draggable="false" to="/admin/gallery/create">
                        new gallery
                    </NavLink>
                </div>
                <div className="w-full flex justify-start items-center flex-row gap-4 dark:text-white">
                    <div onClick={() => setPages("plan")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                        Plans
                        {page === "plan" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                    </div>
                    <div onClick={() => setPages("paint")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                        Paint
                        {page === "paint" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                    </div>
                    <div onClick={() => setPages("big-gallery")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                        gallery
                        {page === "big-gallery" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                    </div>
                    <div onClick={() => setPages("upload")} className="w-auto relative cursor-pointer dark:hover:text-white/70">
                        Upload
                        {page === "upload" ? <span className="w-full absolute h-0.5 bg-blue-500 -bottom-1 left-0 rounded-sm" /> : null}
                    </div>
                </div>
                {page === "paint" ? <PaintComponent user={user} /> : null}
                {page === "plan" ? <PlanComponent user={user} /> : null}
                {page === "upload" ? <UploadComponent user={user} createMessage={createMessage} closeMessage={closeMessage} /> : null}
                {page === "big-gallery" && <GalleryComponent />}
            </div>
        </div>
    );
};

export default Admin;
