import { RouterProvider } from "react-router-dom";
import { Auth } from "./contexts/auth/";
import { useAuth } from "./hook/auth";
import router from "./routes";
import Loader from "./components/loader";
import { Toaster } from "sonner";

const App = () => {
    const { token, user, isloading, getUser } = useAuth();
    return (
        <>
            <Toaster position="bottom-left" expand={false} richColors />
            <Auth.Provider value={{ token, user, reload: getUser }}>{isloading ? <Loader /> : <RouterProvider router={router} />}</Auth.Provider>
        </>
    );
};

export default App;
