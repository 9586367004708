import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _config from "../../config";
import { toast } from "sonner";
import { Errors } from "../../utils/error";
import { Path } from "../../constants";
import NotFound from "../NotFound";
import { TBIG_GALLERY_POPULTE } from "../../@types/big_gallery";
import { manager } from "../../socket";
interface EventImage {
    imageid: string;
    labelid: string;
    username?: string;
    status: "active" | "deactive";
    date: string;
}

const BigGallery = () => {
    const { biggalleryid } = useParams();
    const [gallery, setGallery] = useState<TBIG_GALLERY_POPULTE | null>(null);
    useEffect(() => {
        if (!biggalleryid) return;
        const load = async () => {
            try {
                const rs = await axios.get(`${_config.api_server_uri}${Path.BigGallery.get.replace(/:biggalleryid/, biggalleryid)}`);
                if (rs.status !== 200) return toast.error(rs.data.message);
                console.log(rs.data.gallery);

                setGallery(rs.data.gallery);
            } catch (error) {
                Errors.handler(error);
            }
        };

        load();
    }, [biggalleryid]);
    useEffect(() => {
        if (!gallery) return;
        document.title = `${gallery.name}`;
    }, [gallery]);
    useEffect(() => {
        const socket = manager.socket(Path.socket.biggallery, {
            auth: {
                gallery: biggalleryid,
            },
        });
        socket.on("change-status", (event: EventImage) => {
            if (!gallery) return;
            const galleryData = { ...gallery };
            const image = gallery.images.find((image) => image.id === event.imageid);
            const imageIndex = gallery.images.findIndex((image) => image.id === event.imageid);

            if (image) {
                if (event.status === "active") return;
                galleryData.images.splice(imageIndex, 1);
            } else {
                if (event.status === "deactive") return;

                galleryData.images.push({
                    date: event.date,
                    id: event.imageid,
                    status: event.status,
                    username: event.username,
                });
            }
            galleryData.images.sort((a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            });
            setGallery({ ...galleryData });
        });

        socket.on("disconnect", () => {
            toast.error("socket event disconnect", { position: "top-center" });
        });
        socket.on("reconnect", () => {
            toast.success("socket event reconnect", { position: "top-center" });
        });
        socket.connect();
        return () => {
            socket.off();
            socket.disconnect();
        };
    }, [biggalleryid, gallery]);

    if (!gallery) return <NotFound />;
    return (
        <div className="flex flex-col bg-gray-800 min-h-screen text-white">
            <span className="w-full text-center py-5 text-5xl">{gallery.name.toLocaleUpperCase()}</span>
            <div className="flex justify-center items-center w-full">
                <div
                    className="grid items-center"
                    style={{
                        gridTemplateColumns: `repeat(${gallery.config.image.line_count}, minmax(0, 1fr))`,
                    }}
                >
                    {gallery.images.map((image, index) => {
                        return (
                            <div className="w-full flex items-center justify-center max-w-md" key={index}>
                                <img src={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, image.id)}?width=500&height=500`} alt="" className="w-full" />
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* {JSON.stringify(gallery)} */}
        </div>
    );
};

export default BigGallery;
