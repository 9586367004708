import NavBar from "../../components/navbar";

import { useContext } from "react";
import { Auth } from "../../contexts/auth";
const Home = () => {
    const { user } = useContext(Auth);

    return (
        <div className="h-screen w-full bg-white text-white overflow-x-hidden ">
            <NavBar user={user} />
            <div className="h-auto flex justify-center items-center flex-col">
                <h1 className="text-center text-[5rem] md:text-[8rem] py-10 font-extrabold leading-tight tracking-wide bg-clip-text text-transparent border-2 text-[#86C825]">
                    WELCOME TO
                    <br />
                    ANAGRAM
                </h1>
                {/* <NavLink to="/plans" className="text-center text-2xl block font-bold text-black">see plans</NavLink> */}
                {/* <div className="w-full flex justify-center items-center ">
                    <a
                        href="https://digital-mural.com/digital-activity-contact/"
                        className="text-center text-2xl font-bold mt-5 w-auto p-5 h-14 bg-yellow-500 flex justify-center items-center rounded-xl"
                    >
                        contact
                    </a>
                </div> */}
            </div>
            {/* <div >
                {plans.map((plan) => <Plan key={plan._id} {...plan} />)}
            </div> */}

            <div className="w-full flex justify-center items-center mt-5 text-black px-5 md:px-0">
                <div className="w-full md:w-1/2">
                    <p className="py-2">
                        Step into a realm of artistic expression where words transform into digital works of art. ANAGRAM, our innovative platform, invites you to explore the power of anagrams in the
                        heart of the digital age. Whether you're a literature enthusiast, a poetry lover, a visual artist, or a technology aficionado, ANAGRAM offers a unique experience that fuses
                        language and digital creativity.
                    </p>
                    <p className="py-2">
                        Imagine a place where letters rearrange themselves to form new meanings, where every word becomes a color palette for your imagination. On ANAGRAM, you'll discover an array of
                        digital artistic events that celebrate the endless possibilities of anagrams in English.
                    </p>
                    <p className="py-2">
                        Participate in our interactive workshops led by renowned artists and writers who will guide you through the subtle art of linguistic reinvention. Explore how letters can be
                        manipulated to create captivating narratives, evocative poems, and astonishing visual compositions.
                    </p>

                    <p className="py-2">
                        Attend our live performances where artists merge the verbal and the visual, crafting multisensory experiences that defy traditional communication boundaries. Allow yourself to
                        be transported into a whirlwind of words and shapes, where each artwork is an invitation to rethink meaning and form.
                    </p>

                    <p className="py-2">
                        Our online gallery showcases a constantly evolving collection of digital works created by creative minds from around the world. Explore canvases where letters dance in harmony,
                        where sentences transform into visual landscapes, and where every click brings you closer to a new artistic revelation.
                    </p>

                    <p className="py-2">
                        Join the flourishing community of artists, writers, thinkers, and enthusiasts who are pushing the boundaries of creative expression. Share your own anagrammatic creations,
                        exchange ideas with fellow creative minds, and dive into profound discussions about language, meaning, and the beauty of verbal transformation.
                    </p>
                    <p className="py-2">
                        ANAGRAM is more than just a website—it's a space for discovery, learning, and the celebration of creativity in all its forms. Join us to explore the power of reinvented words
                        and digital artworks that transcend conventions. Join ANAGRAM and uncover a world where letters come to life.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;
