import { z } from "zod";
export const zTool = z
    .object({
        action: z.string({
            invalid_type_error: "tools.action is type of string",
            required_error: "tools.action is required"
        }),
        icon: z.string({
            invalid_type_error: "tools.icon is type of string",
            required_error: "tools.icon is required"
        }),
        text: z.string({
            invalid_type_error: "tools.text is type of string",
            required_error: "tools.text is required"
        }),
        show: z.boolean({
            invalid_type_error: "tools.show is type of boolean",
            required_error: "tools.show is required"
        }),
        size: z.number({ invalid_type_error: "tools.size is type of number", }).optional(),
        cursor_style: z.string({ invalid_type_error: "tools.cursor_style is type of string", }).optional(),
        active: z.boolean({ invalid_type_error: "tools.active is type of boolean", }).optional(),
        iconPath: z.string({ invalid_type_error: "tools.active is type of string", }).optional(),
        html_interface: z
            .object({
                title: z.string({ invalid_type_error: "tools.html_interface.$.title is type of string", required_error: "tools.html_interface.$.title is required" }),
                show: z.boolean({ invalid_type_error: "tools.html_interface.$.show is type of boolean", required_error: "tools.html_interface.$.show is required" }),
                html_templates: z
                    .object({
                        action: z.string({ invalid_type_error: "tools.html_interface.$.html_templates.action is type of string", required_error: "tools.html_interface.$.html_templates.action is required" }),
                        show: z.boolean({ invalid_type_error: "tools.html_interface.$.html_templates.show is type of boolean", required_error: "tools.html_interface.$.html_templates.show is required" }),
                        text: z.string({ invalid_type_error: "tools.html_interface.$.html_templates.show is type of string", required_error: "tools.html_interface.$.html_templates.show is required" }),
                        postUri: z.string({ invalid_type_error: "tools.html_interface.$.html_templates.postUri is type of string", }).optional(),
                        galleryName: z.string({ invalid_type_error: "tools.html_interface.$.html_templates.galleryName is type of string", }).optional(),
                    }, { invalid_type_error: "tools.html_interface.$.html_templates is type of object array", required_error: "tools.html_interface.$.html_templates is required" })
                    .array(),
            }, { invalid_type_error: "tools.html_interface is type of object", })
            .optional(),
        href: z.string({ invalid_type_error: "tools.href is type of string", }).optional(),
        _blank: z.boolean({ invalid_type_error: "tools._blank is type of boolean", }).optional(),
    },
        {
            invalid_type_error: "tools is type of objct array",
            required_error: "tools is required"
        },
    )