import axios, { AxiosError } from "axios";
import _config from "../../config";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "../NotFound";
import { toast } from "sonner";
import { Timage } from "../../@types/image";
import { Path } from "../../constants";
import { manager } from "../../socket";
interface ComposePaint {
    name: string;
    config: Config;
    gallery: {
        images: Timage[];
    };
}

interface EventImage {
    imageid: string;
    username?: string;
    status: "active" | "deactive";
    date: string;
}

const ComposeGallery = () => {
    const [paint, setPaint] = useState<ComposePaint | null>(null);
    const { paintId } = useParams();
    const [imageLine, setimageLine] = useState(0);

    useEffect(() => {
        const load = async () => {
            try {
                if (!paintId) return toast.error("error to find paintid");
                const response = await axios.get(`${_config.api_server_uri}${Path.Gallery.compose.get.replace(/:paintid/, paintId)}`);
                if (response.status !== 200) toast.message(response.data.message);

                setPaint(response.data.paint);
            } catch (error) {
                let message;
                if (error instanceof AxiosError) {
                    if (error.response?.data) {
                        message = error.response.data.message;
                    } else {
                        message = error.message;
                    }
                } else if (error instanceof Error) message = error.message;
                else message = "unknown error";
                toast.error(message);
            }
        };
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const socket = manager.socket(Path.socket.compose, {
            auth: {
                paint: paintId,
            },
        });

        socket.on("disconnect", () => {
            toast.error("socket event disconnect", { position: "top-center" });
        });

        socket.on("reconnect", () => {
            toast.success("socket event reconnect", { position: "top-center" });
        });
        socket.on("connect", () => {});
        socket.connect();
        socket.on("change-status", (event: EventImage) => {
            if (!paint) return;
            const gallery = { ...paint.gallery };
            const image = gallery.images.find((image) => image.id === event.imageid);
            const imageIndex = gallery.images.findIndex((image) => image.id === event.imageid);
            if (image) {
                if (event.status === "deactive") {
                    gallery.images.splice(imageIndex, 1);
                }
            } else {
                gallery.images.push({
                    date: event.date,
                    id: event.imageid,
                    status: event.status,
                    username: event.username,
                });
            }
            gallery.images.sort((a, b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            });
            setPaint((paint) => {
                if (!paint) return null;
                paint.gallery = gallery;
                return { ...paint };
            });
        });

        return () => {
            socket.off();
            socket.disconnect();
        };
    }, [paint, paintId]);
    useEffect(() => {
        const urlsearchparams = new URLSearchParams(window.location.search);
        if (paint) {
            document.title = `${paint.config.title.gallery.replace(/{{name}}/, paint.name)}`;
        }

        if (urlsearchparams.has("imageLine")) {
            const imageLinequery = Number(urlsearchparams.get("imageLine"));
            if (imageLinequery) {
                setimageLine(imageLinequery);
            }
        } else {
            if (paint) {
                setimageLine(paint.config.gallery.image_line.count);
            }
        }
    }, [paint]);

    return (
        <div className="w-screen overflow-x-hidden">
            {paint ? (
                <div className="dark:bg-gray-800 dark:text-white relative w-screen h-screen">
                    <div className="w-screen h-16 flex justify-center items-center">
                        <h2 className="text-3xl">{paint.name}</h2>
                    </div>

                    {paint.gallery ? (
                        <div className="flex items-center justify-center h-auto dark:bg-gray-800 flex-col">
                            <div className="flex justify-center items-center flex-col my-10">
                                <div className="grid grid-cols-4 items-center" style={{ gridTemplateColumns: `repeat(${imageLine}, minmax(0, 1fr)` }}>
                                    {paint.gallery.images.map((image, index) => {
                                        return (
                                            <div className="w-full flex items-center justify-center max-w-md" key={index}>
                                                <img src={`${_config.api_server_uri}${Path.Gallery.render.replace(/:imageid/, image.id)}?width=500&height=500`} alt="" className="w-full" />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-1/2 flex justify-center items-center opacity-60">compose empty</div>
                    )}
                </div>
            ) : (
                <NotFound />
            )}
        </div>
    );
};

export default ComposeGallery;
// export const loader: LoaderFunction = async ({ params }) => {
//     // try {
//     //     const res = await axios.post(`${config.api_server_uri}/paint/compose/gallery/${params.paintId}`);
//     //     if (res.status === 200) {
//     //         return res.data;
//     //     } else {
//     //         return null
//     //     }
//     // } catch (err) {
//     //     return null
//     //     // return redirect('/compose')
//     // }
// };
